<template>
    <v-navigation-drawer
		v-if="$vuetify.breakpoint.smAndDown"
		v-model="drawer"
		disable-resize-watcher
		:width="drawer ? 300 : 0"
		class="q-mobile-drawer"
		@input="updateValue"
	>
		<div class="q-mobile-drawer__navigation-list-wrapper">
			<div class="text-right">
				<v-btn dark icon fab @click="close">
					<v-icon>fas fa-times</v-icon>
				</v-btn>
			</div>

			<div class="px-4" v-if="$vuetify.breakpoint.xs">
				<QSearchInput class="pa-0" dark clearable />         
			</div>
	
			<MainNavigationList />
		</div>
    </v-navigation-drawer>
</template>

<script>
import MainNavigationList from './MainNavigationList.vue'
import QSearchInput from '@/components/utils/QSearchInput.vue'

export default {
	components: {
        MainNavigationList,
        QSearchInput,
    },
    props: {
		value: { type: Boolean },
	},
    data () {
        return {
            drawer: false,
        }
    },
    methods: {
        updateValue () {
            this.$emit('input', this.drawer)
        },
        close () {
            this.drawer = false
            this.updateValue()
        },
    },
    watch: {
        value (newV) {
            this.drawer = newV
        },
        $route() {
            if (this.$vuetify.breakpoint.xs) {
                this.drawer = false
            }
        },
    },
}
</script>

<style lang="scss">
.q-mobile-drawer {	
	&__navigation-list-wrapper {
		padding-bottom: 0.5rem;
		background-color: var(--app-color-primary);
		width: var(--app-left-drawer-width);
	}
}
</style>
