
import { NoticePopup } from '@/store/API/PopupAcknowledgements';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    message: { type: Object as PropType<NoticePopup>, required: true },
    color: { type: String, default: 'q_messages_3' }
  },

  computed: {
    doShowAcknowledgeButton() {
      return this.message?.add_acknowledge_btn == 1 || !this.message?.link
    },
    doShowLinkButton() {
      return this.message?.link != null
    },
    computedLink() {
      if (!this.message?.link) { return null }
      if (this.message?.link?.startsWith('/')) {
        return this.message.link
      }
      if (/^https?:\/\//.test(this.message?.link)) {
        return this.message.link
      }
      return `https://${this.message.link}`
    }
  },

  methods: {
    onAcknowledgeLinkClicked() {
      this.$emit('link-clicked')
      this.$emit('acknowledge')
    },
    onAcknowledgeClicked() {
      this.$emit('acknowledge-clicked')
      this.$emit('acknowledge')
    }
  }
})
