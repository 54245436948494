const routes = [{
    path: '/support/tickets',
    name: 'SupportTickets',
    meta: {
        title: 'Support Tickets'
    },
    component: function() {
        return import( /* webpackChunkName: "support" */ '../views/Support/SupportTickets.vue')
    },
}, {
    path: '/support/tickets/create',
    name: 'CreateTicket',
    meta: {
        title: 'Create Support Ticket'
    },
    component: function() {
        return import( /* webpackChunkName: "support" */ '../views/Support/CreateTicket.vue')
    },
}, {
    path: '/support/:department?',
    name: 'Support',
    meta: {
        title: 'Support'
    },
    component: function() {
        return import( /* webpackChunkName: "support" */ '../views/Support/index.vue')
    },
}, {
    path: '/support/tickets/:id',
    name: 'ViewTicket',
    meta: {
        title: 'View Tickets'
    },
    component: function() {
        return import( /* webpackChunkName: "support" */ '../views/Support/ViewTicket.vue')
    },
}, {
    path: '/support/solution/:category',
    name: 'SupportSolution',
    meta: {
        title: 'Common Solutions'
    },
    props: true,
    component: function() {
        return import( /* webpackChunkName: "support" */ '../views/Support/NewSupportSolution.vue')
    },
}]

export default routes