<template>
	<div class="q-marketing-resources-table">
        <MarketingResourceFilters
            v-model="filters"
            :filters-variants="filtersVariants"
        />

        <h4 class="q-marketing-resources-table__title">{{ title }}</h4>
		<v-data-table
			class="q-marketing-resources-table__table"
			:headers="[
				{ text: 'Resource', value: 'DocTitle', width: '100%' },
				{ text: 'Audience', value: 'Audience', align: 'center', width: '150px' },
				{ text: 'Type', value: 'DocType', width: '100px' },
				{ text: '', value: 'download_url', sortable: false },
			]"
			v-model="mSelected"
			:items="filteredItems"
			:expanded.sync="expanded"
			:mobile-breakpoint="0"
			item-key="ID"
			no-data-text="No documents found"
			show-select
		>
			<template #expanded-item="{ item }">
				<td colspan="100">
					<div class="q-marketing-resources-table__description q-marketing-resources-table-description">
						<div class="q-marketing-resources-table-description__header">
							<p class="q-marketing-resources-table-description__label">Description</p>
							<span class="q-marketing-resources-table-description__updated">
								Updated {{ item.LastChangeDate }}
							</span>
						</div>
						<p class="mb-0">{{ item.DocDesc }}</p>
					</div>
				</td>
			</template>
	
			<template #item.Audience="{ item }">
				<v-chip :color="item.Audience === 'Agent' ? '#0083EB' : '#46C3B2'" dark>
					{{ item.Audience }}
				</v-chip>
			</template>
	
			<template #item.DocType="{ item }">
				<div class="q-marketing-resources-table__type-column">
					<v-icon>{{ getDocIcon(item.DocType) }}</v-icon>
					{{ item.DocType }}
				</div>
			</template>
	
			<template #item.download_url="{ item }">
				<div class="q-marketing-resources-table__actions">
					<v-btn text small @click.stop="previewDocument(item)">
						<v-icon>fas fa-eye</v-icon>
					</v-btn>
					<v-btn text small :href="item.DownloadLink" target="_blank">
						<v-icon>fas fa-download</v-icon>
					</v-btn>
					<v-btn text small @click.stop v-clipboard:copy="item.ViewLink" v-clipboard:success="handleCopyStatus">
						<v-icon>fas fa-copy</v-icon>
					</v-btn>
					<v-btn text small @click="toggleExpand(item)">
						<v-icon>fas fa-chevron-down</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<QDocPreview v-model="showPreview" :doc="currentDoc" />
	</div>
</template>

<script>
import QDocPreview from '@/CMS/editor/components/QDocPreview.vue'
import QDocPreviewMixin from '@/CMS/editor/components/QDocPreviewMixin'
import MarketingResourceFilters from './Filters.vue'

const WITHOUT_SUBJECTS = '- Without Subjects -'

export default {
  	name: 'MarketingResourceTable',

	components: {
		QDocPreview,
        MarketingResourceFilters,
	},

	mixins: [QDocPreviewMixin],

	props: {
		items: { type: Array },
		subjects: { type: Array },
		selected: { type: Array, default: () => [] },
		title: { type: String },
	},

	data () {
		return {
			expanded: [],
			showPreview: false,
			currentDoc: {},
			filters: {
				search: '',
				type: '',
				subject: '',
				audience: '',
            },
		}
	},

	computed: {
		mSelected: {
			get () { return this.selected },
			set (v) { this.$emit('update:selected', v) },
		},
		filteredItems () {
            const search = this.filters.search.toLowerCase()

			return this.items
				.filter(({ DocTitle, DocDesc }) => (DocTitle.toLowerCase().includes(search) || DocDesc?.toLowerCase().includes(search)))
				.filter(({ DocumentTags }) => {
					if (!this.filters.subject) { return true }
					if (this.filters.subject === WITHOUT_SUBJECTS && !DocumentTags.length) { return true }
					return DocumentTags.includes(this.filters.subject)
				})
				.filter(({ DocType }) => {
					return this.filters.type ? DocType === this.filters.type : true
				})
				.filter(({ Audience }) => {
					return this.filters.audience ? Audience === this.filters.audience : true
				})
		},
		filtersVariants () {
			const types = this.items
				.filter(({ DocumentTags }) => {
					if (!this.filters.subject) { return true }
					if (this.filters.subject === WITHOUT_SUBJECTS && !DocumentTags.length) { return true }
					return DocumentTags.includes(this.filters.subject)
				})
				.map(({ DocType }) => DocType)

			const filteredByDocType = this.items
				.filter(({ DocType }) => this.filters.type ? DocType === this.filters.type : true)
			const flatTags = filteredByDocType.map(({ DocumentTags }) => DocumentTags).flat()
			const hasWithoutDocumentTags = filteredByDocType.find(({ DocumentTags }) => !DocumentTags.length)
			const uniqueTags = [...new Set(flatTags)].sort()
			const tags = hasWithoutDocumentTags ? [WITHOUT_SUBJECTS, ...uniqueTags] : uniqueTags
			const subjects = this.subjects.length
				? tags.filter((subject) => this.subjects.includes(subject))
				: tags

			return {
				types: [...new Set(types)].sort(),
				subjects,
			}
		},
	},

	watch: {
		showPreview (val) {
            if (!val) { this.currentDoc = {} }
        },
	},

	methods: {
        previewDocument (doc) {
			this.currentDoc = doc
			this.showPreview = true
        },
		toggleExpand (item) {
			if (this.expanded.includes(item)) {
				this.expanded = this.expanded.filter((i) => i !== item)
			} else {
				this.expanded.push(item)
			}
		},
		handleCopyStatus () {
			this.showInfo('Document URL copied to clipboard!')
		},
	},
}
</script>

<style lang="scss" scoped>
.q-marketing-resources-table {
	&__table {
		width: 100%;
	}

    &__title {
		padding: 0 1rem;
		font-size: 18px;
		font-weight: 600 !important;
    }

	&__type-column {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	&__description {
		padding: 1rem;
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.v-icon {
			opacity: 0.5;
			transition: opacity 0.2s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

    ::v-deep .v-data-table__checkbox.v-simple-checkbox {
      .v-icon:not(.fa-square) {
        color: #1383eb !important;
      }
    }
}

.q-marketing-resources-table-description {
	&__header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	&__label, &__updated {
		font-size: 0.75rem;
		margin: 0;
	}

	&__label {
		font-weight: bold;
	}
}
</style>
