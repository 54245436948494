<template>
    <div class="q-summit-guide-step-text">
        <h6 class="q-summit-guide-step-text__title" :style="{ color: titleColor }">
            {{ title }}

            <v-icon 
                v-if="isComplete" 
                class="q-summit-guide-step-text__check-icon ml-1 vuetify-fix-icon vuetify-fix-icon--small"
                color="white"
                :class="color"
            >
                fas fa-check
            </v-icon>


            <span v-if="inProgress" class="ml-2 text-capitalize" :style="{ color: getColor(color) }">In Progress</span>
        </h6>

        <p class="q-summit-guide-step-text__description" :style="{ color: textColor }">{{ description }}</p>
    </div>
</template>

<script>
const STEP_TITLE_COLORS = {
    Milestone: 'q_support_1',
    Checkpoint: 'q_leads_1',
    Step: '#000'
}

export default {
    props: {
        title: { type: String, required: true },
        description: { type: String, required: true },
        color: { type: String, required: true },
        inProgress: { type: Boolean, required: true },
        isComplete: { type: Boolean, required: true },
        disabled: { type: Boolean, required: false }, 
        type: { type: String, required: true },
    },

    computed: {
        titleColor() {
            if (this.disabled) { return "#ADADAD" }

            return this.getColor(STEP_TITLE_COLORS[this.type])
        },
        textColor() {
            if (this.disabled) { return "#ADADAD" }

            return '#000'
        }
    }
}
</script>

<style lang="scss">
    .vuetify-fix-icon.vuetify-fix-icon {
        &--small {
            font-size: 8px !important;
        }

        font-size: 12px !important;
    }

    .q-summit-guide-step-text {
        display: inline-flex;
        flex-direction: column;

        &__title {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: black;
            font: normal normal bold 10px/13px Montserrat;
            letter-spacing: 0px;
        }

        &__description {
            font: normal normal normal 13px/16px Montserrat;
            letter-spacing: 0px;
            margin-bottom: 0 !important;
        }

        &__check-icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 4px;
        }
    }
</style>