<template>
    <div class="q-compass-percentage">    
        <svg version="1.1" class="q-compass-percentage__svg" id="Compass-Percentage" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 112 112" style="enable-background:new 0 0 112 112;" xml:space="preserve">
            <g id="Compass-Percentage-Graphic-94-94" transform="translate(-16 -78)">
                <g transform="matrix(1, 0, 0, 1, 16, 78)">
                    <circle id="Compass-Background-Circle_94x94-2_1_" class="st0" cx="56" cy="53" r="47" />
                </g>
                <g id="Compass-Percentage-Done_72x72" transform="translate(746.835 1523.966)">
                    <path class="st1" d="M-674.8-1425.6v6.8c14.3,0,25.9,11.6,25.9,25.9s-11.6,25.9-25.9,25.9c-14.3,0-25.9-11.6-25.9-25.9l0,0
                    c0-14.3,11.6-25.9,25.9-25.9V-1425.6c-18,0-32.7,14.6-32.7,32.7c0,18,14.6,32.6,32.7,32.6c18,0,32.6-14.6,32.6-32.6
                    C-642.2-1411-656.8-1425.6-674.8-1425.6z" />
                    <path class="st2" :stroke-dasharray="progress_ind" d="M-674.9-1422.6c16.4,0,29.7,13.3,29.7,29.7c0,16.4-13.3,29.7-29.7,29.7l0,0c-16.4,0-29.7-13.3-29.7-29.7
                    C-704.5-1409.2-691.3-1422.6-674.9-1422.6" />
                    <g id="Group_58" transform="translate(-710.835 -1428.221)">
                        <g id="Ellipse_15" transform="translate(1.857 1.129)">
                            <ellipse class="st3" cx="34.1" cy="34.1" rx="34.1" ry="34.1" />
                            <ellipse class="st4" cx="34.1" cy="34.1" rx="33.4" ry="33.4" />
                        </g>
                        <line id="Line_11" class="st5" x1="0" y1="35" x2="5.4" y2="35" />
                        <line id="Line_12" class="st5" x1="66.4" y1="35" x2="72" y2="35" />
                        <line id="Line_13" class="st5" x1="36" y1="-1" x2="36" y2="4" />
                        <line id="Line_14" class="st5" x1="36" y1="65.9" x2="36" y2="71.5" />
                    </g>
                </g>
            </g>
        </svg>
        <span class="progress-text st6 st7">
            {{displayNumber}}%
        </span>
    </div>
</template>

<script>
export default {
    props: {
        totalSteps: { type: [Number, String], default: 0 },
        currentStep: { type: [Number, String], default: 0 },
        color: { type: String, default: '#015851' }
    },

    data() {
        return {
            displayNumber: 0,
            interval: false
        };
    },

    mounted() {
    	this.displayNumber = parseInt(this.finalNumber) ? parseInt(this.finalNumber) : 0;
    },

    ready () {
	    this.displayNumber = this.finalNumber ? this.finalNumber : 0;
	  },

    computed:{
        "finalNumber" : function(){
            return Math.round(this.currentStep / this.totalSteps * 100)
        },
    	"progress_ind" : function(){
    		var p = 186 * (this.displayNumber / 100)
    		return p + ", 186"
    	},
    },

    watch: {
    	finalNumber () {
	      clearInterval(this.interval);

	      if(this.finalNumber == this.displayNumber) {
	        return;
	      }

	      this.interval = window.setInterval(() => {
	        if(this.displayNumber != this.finalNumber) {
	          var change = (this.finalNumber - this.displayNumber) / 10;
	          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
	          this.displayNumber = this.displayNumber + change;
	        }
	      }, 20);
	    }
    },
}
</script>

<style lang="scss">
.q-compass-percentage {
    position: relative;

    &__svg {
        filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.33));
        height: 100%;
        width: 100%;
    }

    .st0 {
        fill: #FFFFFF;
    }

    .st1 {
        fill: v-bind(color);
        opacity: 0.2;
    }

    .st2 {
        fill: none;
        stroke: v-bind(color);
        stroke-width: 7.7606;
        animation: progress 1s ease-out forwards;
    }

     .st3 {
        fill: none;
    }

     .st4 {
        fill: none;
        stroke: v-bind(color);
        stroke-width: 1.5;
    }

     .st5 {
        fill: none;
        stroke: v-bind(color);
        stroke-width: 1.5;
        stroke-linecap: round;
    }

    .st6 {
        font-family: 'Montserrat-Black';
    }

    .st7 {
        font-size: 13px;
    }

    .progress-text {
        font: normal normal 900 16px/16px Montserrat;
        letter-spacing: 0px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-75%);
        color: v-bind(color);
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 186;
    }
}
</style>
