<template>
    <v-hover v-slot:default="{ hover }">
        <v-carousel :cycle="!hover" continuous :height="height" interval="8000" hide-delimiters show-arrows-on-hover
            class="cms">
            <v-carousel-item v-if="loading" class="text-center">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-carousel-item>
            <v-carousel-item v-else v-for="(block, key) in slider_content.blocks" :key="key"
                @click="maybeOpenLink(block)" :class="isClickable(block) ? 'clickable' : ''">
                <v-responsive height="60" class="d-none d-md-flex">
                    <div :class="blockClasses(block, 'my-7')">
                        <div :is="block.type" :content-data="block"></div>
                    </div>
                </v-responsive>
                <v-responsive height="130" class="d-md-none">
                    <div :class="blockClasses(block, 'my-7')">
                        <div :is="block.type" :content-data="block"></div>
                    </div>
                </v-responsive>
            </v-carousel-item>
        </v-carousel>
    </v-hover>
</template>
<script>
import Page from '@/store/Models/Page'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue';
import TwoCol5050 from '@/CMS/views/ContentBlocks/TwoCol5050.vue';
import TwoCol2575 from '@/CMS/views/ContentBlocks/TwoCol2575.vue';
import TwoCol7525 from '@/CMS/views/ContentBlocks/TwoCol7525.vue';
import ThreeCol255025 from '@/CMS/views/ContentBlocks/ThreeCol255025.vue';
import ThreeCol333333 from '@/CMS/views/ContentBlocks/ThreeCol333333.vue';
import FourCol25252525 from '@/CMS/views/ContentBlocks/FourCol25252525.vue';
import CardStyles from '@/CMS/views/ContentBlocks/CardStyles.vue';
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue';
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue';
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue';
import Banner from '@/CMS/views/ContentBlocks/Banner.vue';
import Leaderboard from '@/CMS/views/ContentBlocks/Leaderboard.vue';
import moment from 'moment'

export default {
    props: {
        slug: { type: String },
        height: { type: [String, Number], default: 'auto' },
    },
    data: function () {
        return {

        }
    },
    mounted: function () {
        var g = this
        if (Object.keys(this.slider_content).length == 0) {
            QuilityAPI.getPageWithSlug(this.slug).then(function () { })
        }
    },
    computed: {
        slider_content: function () {
            var page = Page.query().where('slug', this.slug).first();
            if (page != null) {
                page.blocks = page.blocks.filter(block => {
                    if (block.status === 'draft')
                        return false

                    if (block.startDate !== null && block.startDate !== undefined && block.startDate !== "") {
                        if (!moment(block.startDate).isSameOrBefore(moment(), 'day'))
                            return false
                    }

                    if (block.endDate !== null && block.endDate !== undefined && block.endDate !== "") {
                        if (!moment(block.endDate).isSameOrAfter(moment(), 'day'))
                            return false
                    }

                    return true
                })
                return page
            }
            return {}
        },
        'loading': function () {
            return this.$store.state.entities.page.loading
        },
    },
    methods: {
        blockClasses(block, other) {
            if (typeof block.styles != 'object') {
                this.$set(block, 'styles', [])
            }
            var classes = [
                ...block.styles,
                block.type,
                other
            ]
            return classes.join(' ')
        },
        isClickable: function (block) {
            if (block.link != null && block.link != '') {
                return true
            }
            return false
        },
        maybeOpenLink: function (block) {
            console.log(block)
            if (block.link != null && block.link != '') {
                if (tblock.link.indexOf('http') > -1) {
                    window.open(block.link, "_blank")
                } else {
                    this.$router.push(block.link)
                }
            }
        }
    },
    watch: {
        'slider_content': function (newV) {
            //tests for empty object
            if (Object.keys(newV).length === 0) {
                QuilityAPI.getPageWithSlug(this.slug).then(function () { })
            }
        }
    },
    components: {
        CallToAction,
        FullWidthText,
        TwoCol5050,
        TwoCol2575,
        TwoCol7525,
        ThreeCol333333,
        ThreeCol255025,
        FourCol25252525,
        CardStyles,
        ButtonGroup,
        CardGroup,
        IframeGroup,
        Banner,
        ZendeskText,
        Leaderboard
    }
}

</script>
