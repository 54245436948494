<template>
	<v-btn
		depressed
		:ripple="false"
		class="q-app-bar-btn"
		:class="btnClass"
		@click="showSearch"
	>
		<v-icon>icon-q-search</v-icon>
	</v-btn>
</template>

<script>
export default {
    computed: {
        isSearch () {
			return this.$store.state.show_search
		},
		isMobile () {
			return this.$vuetify.breakpoint.xs
		},
		btnClass () {
			return [
				this.isMobile && 'q-app-bar-btn--mobile',
				this.isSearch && 'q-app-bar-btn--active',
			]
		},
    },
    methods: {
        showSearch () {
            this.$store.commit('updateShowSearch', true)
        },
    },
}
</script>

<style lang="scss" scoped>
.q-app-bar-btn {
	.v-icon.v-icon {
		font-size: 24px !important;
	}

	&--mobile {
		.v-icon.v-icon {
			font-size: 20px !important;
		}
	}
}
</style>
