const routes = [{
        name: "CarriersEAppPlatforms",
        path: '/carriers/carriers-e-app-platforms',
        component: () => import( /* webpackChunkName: "carriers" */ '../views/Carriers/carriers-e-app-platforms.vue'),
        meta: {
            title: 'Carriers e-App Platforms'
        },
    },
    
]

export default routes