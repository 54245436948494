const routes = [{
        path: '/hub-pages',
        name: 'HubPages',
        component: () => import( /* webpackChunkName: "hubPages" */ '../modules/HubPages/views/HubPages.vue'),
        meta: {
            permission: ['manage:Content'],
			title: 'Hub Pages',
        },
    }, {
        path: '/hub-page/:slug',
        name: 'HubPage',
        component: () => import( /* webpackChunkName: "hubPage" */ '../modules/HubPages/views/HubPage.vue'),
		meta: {
			title: 'Hub Page' /* will populate from Hup page title */
		}
    }, {
        path: '/hub-page-audit/:pageId',
        name: 'HubPageAudit',
        component: () => import( /* webpackChunkName: "hubPageAudit" */ '../modules/HubPages/views/HubPageAudit.vue'),
    },
]

export default routes
