export const performDashColors: Record<string, string> = {
	'performdash-weekly-survey': '#166172',
	'performdash-monthly-survey': '#164B44',
	'performdash-quarterly-survey': '#53488D',
}

export const performDashLabel: Record<string, string> = {
	'performdash-weekly-survey': 'Weekly',
	'performdash-monthly-survey': 'Monthly',
	'performdash-quarterly-survey': 'Quarterly',
}

export const performDashDescription: Record<string, string> = {
	'performdash-weekly-survey': '<h3>Weekly Survey</h3><p>Embrace this 5 minute weekly survey opportunity to gain a deeper understanding of yourself and unlock the door to a more successful next week. By reflecting on areas such as prioritization, energy management, calendar utilization, and focus, you embark on a journey of self-discovery that empowers you to refine your strategies for peak performance. Let’s grow!</p>',
	'performdash-quarterly-survey': '<h3>Quarterly Survey</h3><p>Each quarter we ask you to take about 10 minutes to share your insights in this survey about your leader which can shape the health and growth of your agency in a positive way. Your input is the key to creating a positive leadership environment. By investing this brief time, you contribute to a culture of continuous improvement, where your feedback becomes the catalyst for positive changes that enhance both your professional growth and the overall success of your team.</p>',
}

export const getSurveyColor = (slug?: string) => {
	if (!slug) { return }
	return performDashColors[slug]
}

export const getSurveyLabel = (slug?: string) => {
	if (!slug) { return }
	return performDashLabel[slug]
}

export const getSurveyDescription = (slug?: string) => {
	if (!slug) { return }
	return performDashDescription[slug]
}

export const checkPerformDashFml = (slug?: string) => {
	if (!slug) { return false }
	return Object.keys(performDashColors).includes(slug)
}
