<template>
    <div class="q-dashboard q-dashboard--mobile grey lighten-5">
        <v-container fluid class="q-dashboard__content px-5">
            <q-page-slider class="q-page-slider" height="auto" slug="homepage-slider"></q-page-slider>

            <v-row class="mb-4">
                <v-col>
                    <v-btn to="/contests" color="black" depressed dark width="100%" class="text-none">
                        Contests
                    </v-btn>
                </v-col>

                <v-col>
                    <v-btn to="/news" color="black" depressed dark width="100%" class="text-none">
                        News
                    </v-btn>
                </v-col>
            </v-row>

            <div v-if="hasDivision(['Corporate'])" class="q-dashboard__links">
                <v-btn to="/page/available-carriers" color="#46C3B1" class="mb-2 text-none" depressed dark width="100%">Carrier Resources</v-btn>
                <v-btn to="/page/corp-calls-and-webinars" color="#0383EA" class="mb-2 text-none" depressed dark width="100%">Webinars</v-btn>
                <!-- <v-btn to="/page/corp-calls-and-webinars#past-calls" color="#4DBDF9" class="mb-2 text-none" depressed dark width="100%">Past Webinars</v-btn> -->
                <v-btn to="https://dfl.quility.com/auth" color="#6ecf93" class="mb-2 text-none" depressed dark width="100%">Agency Rocket</v-btn>
                <v-btn to="/support" color="#085851" class="mb-2 text-none" depressed dark width="100%">Support</v-btn>
            </div>

            <div v-else class="q-dashboard__links">
                <v-btn to="/leads" color="q_leads_1" class="mb-2 text-none" depressed dark width="100%">Leads</v-btn>
                <v-btn to="/agents/carriers" color="#46C3B1" class="mb-2 text-none" depressed dark width="100%">Carriers</v-btn>
                <v-btn to="/page/calls-and-webinars" color="#0383EA" class="mb-2 text-none" depressed dark width="100%">Join the Webinar</v-btn>
                <v-btn to="/page/calls-and-webinars#past-calls" color="#4DBDF9" class="mb-2 text-none" depressed dark width="100%">Past Webinars</v-btn>
                <v-btn to="/support" color="#085851" class="mb-2 text-none" depressed dark width="100%">Support</v-btn>
            </div>
        </v-container>
        <div class="q-dashboard__search-bar px-5">
            <QSearchInput />
            <v-icon class="ml-2" v-text="'fas fa-search'" />
        </div>
    </div>
</template>

<script>
import QPageSlider from '@/components/utils/QPageSlider.vue';
import QSearchInput from '@/components/utils/QSearchInput.vue';

export default {
    name: 'DashboardMobile',

    components: {
        QPageSlider, QSearchInput,
    },
};
</script>

<style lang="scss">
  .q-dashboard {
    height: 100%;

    &--mobile {
      display: flex;
      flex-direction: column;
    }

    &__content {
      flex: 1;
    }

    &__search-bar {
      display: flex;
      background: #F4F4F4;
    }
  }
</style>
