<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				:ripple="false"
				class="q-app-bar-btn"
				:class="btnClass"
				@click="switchSummitSidebar"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon>icon-q-summit-icon</v-icon>
			</v-btn>
		</template>
		<span>Summit</span>
	</v-tooltip>
</template>

<script>
import { store } from './store/sidebar-store'

export default {
    computed: {
        doShowDrawer: {
            get () { return store.doShowSummitSidebar },
            set (val) { store.doShowSummitSidebar = val }
        },
		isMobile () {
			return this.$vuetify.breakpoint.xs
		},
		btnClass () {
			return [
				this.isMobile && 'q-app-bar-btn--mobile',
				this.doShowDrawer && 'q-app-bar-btn--active',
			]
		},
    },
    methods: {
        switchSummitSidebar() {
            this.doShowDrawer = !this.doShowDrawer

            if (this.doShowDrawer) {
                this.$amplitude.track('Q2A_SummitDrawer')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.q-app-bar-btn {
	.v-icon.v-icon {
		font-size: 24px !important;
	}

	&--mobile {
		.v-icon.v-icon {
			font-size: 20px !important;
		}
	}
}
</style>
