<template>
	<div class="q-marketing-resources">
        <div style="height: 4px;"><v-progress-linear v-if="loading" indeterminate /></div>

		<div v-if="!loading && !Object.keys(groupedItems).length">
			<p class="px-4 mt-6">No documents found</p>
		</div>

		<template v-else-if="!loading">
			<div class="my-4">
				<QButton depressed color="#1383eb" :disabled="!selectedItemsIDs.length || bulkDownloading" :loading="bulkDownloading" @click="downloadBulkSelected">Download Selected</QButton>
			</div>

			<template v-for="(items, group) in groupedItems">
				<div
					v-if="items.length > 0"
					:key="group"
					class="q-marketing-resources__group"
				>
					<MarketingResourceTable
						:items="items"
						:selected="selected[group]"
                        :subjects="subjects"
                        :title="group"
						@update:selected="selected[group] = $event"
					/>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import { groupBy } from 'lodash'

import QuilityAPI from '@/store/API/QuilityAPI'
import MarketingResourceTable from '@/components/document_library/marketing_resource/Table.vue'
import QButton from '@/modules/shared/components/QButton.vue'

const WITHOUT_SUBJECTS = '- Without Subjects -'

export default {
  	name: 'MarketingResource',

  	components: { MarketingResourceTable, QButton },

	props: {
		contentData: { type: Object, required: true },
	},

	data () {
		return {
			data: [],
			loading: false,
			selected: {},
			bulkDownloading: false,
		}
	},

	mounted () {
		this.loadData()
	},

	computed: {
		groups () {
			return this.contentData.groups || []
		},
		subjects () {
			return this.contentData.tags || []
		},
		groupedItems () {
			if (!this.data.length) { return {} }
			return groupBy(this.data, 'SubCategory')
		},
		selectedItemsIDs () {
			return Object.values(this.selected).flat().map((item) => item.ID)
		},
        watcherCasheKey() {
            return this.groups.join('') + this.subjects.join('')
        },
	},

    watch: {
        watcherCasheKey() {
            this.loadData()
        },
		contentData () {
			this.loadData()
		},
		groupedItems () {
			this.formSelected()
		},
	},

	methods: {
		async loadData () {
			this.loading = true
			const response = await QuilityAPI.getMarketingResourceLibrary({
				SubCategory: this.groups.join(','),
				DocumentTags: this.subjects.join(',').replace(WITHOUT_SUBJECTS, '-empty-'),
			})
			if (response.error) {
				this.showError('Could not load Marketing Resource Library', response.msg)
			} else {
				this.data = response.data
			}
			this.loading = false
		},
		formSelected () {
			const groups = Object.keys(this.groupedItems)
			this.selected = {}
			if (!groups.length) { return }
			groups.forEach(group => {
				this.$set(this.selected, group, [])
			})
		},
		downloadBulkSelected () {
			if (!this.selectedItemsIDs.length) { return }
			const params = {
				'itemsPerPage': 'zip',
				'DocumentIds': this.selectedItemsIDs.join(','),
			}
			const job = {
				'method': 'GET',
				'path': '/api/private/marketing_resource_library/bulk-download',
				'params': params,
				'JobName': 'MRL Export',
			}
			this.dispatchJob(job)
		},
	},
}
</script>

<style lang="scss">
.q-marketing-resources {
	border: 1px solid #ccc;
	padding: 1rem;

	&__group {
		margin-top: 1.5rem;
	}

	.v-input {
		input {
			&::-webkit-input-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &:placeholder-shown, &::placeholder { /* Safari, Chrome and Opera */
				opacity: 0.8;
			}
		}
	}
}
</style>