<template>
    <div>
        <q-score-card key="QScoreCardPlaced" title="Placed" api-endpoint="placed" drill-downLabel="Agent Breakdown" drill-down-route="Drilldown_Placed" card-style="production" :agent-code="agentCode" :filters="filters" :default-goal="defaultGoal" format="Currency">
            Note: Placed stats are only available after the end of the month - updated once a month.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardPlaced",
    props: ["agentCode", "filters", "defaultGoal"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {},
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
