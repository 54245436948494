const routes = [{
        path: '/page/quility-u/',
        name: 'OnlineLearning',
        meta: {
            title: 'Online Learning'
        },
    },
    {
        path: '/training/product-concepts/',
        name: 'ProductConcepts',
        component: () => import( /* webpackChunkName: "quilityu" */ '../views/content/ProductConcepts.vue'),
        meta: {
            title: 'Product Concepts'
        },
    },
]

export default routes