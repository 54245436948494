<template>
    <v-footer color="blue-grey" class="white--text q-footer mt-4">
        <v-row class="d-flex justify-space-around flex-wrap pl-12 pr-3 my-2">
            <div class="spacer" style="max-width:1px;"></div>
            <div>
                <span>{{ release.Version }}{{ release.MinorVersion }}</span> -
                <router-link to="/page/hq-release-notes">Release Notes</router-link>
            </div>
            <div>
                <span v-if="user.currentSetDivision">
                    <span v-if="user.currentSetDivision == 'Field'">Symmetry</span>
                    <span v-else-if="user.currentSetDivision == 'Corporate'">Asurea</span>
                    <span v-else>{{ user.currentSetDivision }}</span>
                </span>
                <span class="pl-1">&copy; 2021 - {{ currentYear }}</span>
            </div>
        </v-row>
            <v-card style="min-width:100%;" class="mb-3" v-if="footerSliderVisible">
                <v-expand-transition>
                    <div v-show="showBanner" class="reimagine pl-0 pr-0 ml-0 mr-0 ">
                        <v-btn fab x-small absolute bottom right dark rounded-circle color="#1a1e3f"
                            @click="showBanner = !showBanner" style="border-radius:50%" class="mp-1 rounded-circle">
                            <v-icon x-small>{{ showBanner ? 'fas fa-times' : 'fas fa-times' }}</v-icon>
                        </v-btn>
                        <q-footer-slider class="q-footer-slider" height="auto" slug="footer-slider"></q-footer-slider>
                    </div>
                </v-expand-transition>
            </v-card>
    </v-footer>
</template>
<script>
import ReleaseMeta from '@/ReleaseMeta.json'
import QFooterSlider from "@/components/utils/QFooterSlider.vue";

const currentYear = new Date().getFullYear();

export default {
    components: {
        QFooterSlider
    },
    data() {
        return {
            showBanner: true,
            release: ReleaseMeta,
            currentYear,
        }
    },
    computed: {
        footerSliderVisible() {
            return this.hasPermission('division:Field')
        },
    },
}
</script>

<style>
    .acsb-trigger.acsb-trigger-position-y-bottom {
        bottom: 75px !important;
    }
</style>
