<template>
	<v-dialog
		v-if="doShowDialog"
		:value="true"
		persistent
		width="100%"
		max-width="960px"
	>
		<MessageCard 
			:message="message"
			:has-badge="hasBadge"
			@close="closeMessage"
		/>
	</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import moment from 'moment'
import Cookies from 'js-cookie'

import { EventBus } from '@/event-bus'
import MessageCard from './GratitudesJournalMessageCard.vue'

const GRATITUDES_CHALLENGE_TITLE = '30-Day Gratitude Challenge + Journal'

const GRATITUDES_CHALLENGE_COOKIE = 'gratitudes-challenge-daily-reminder'

const MESSAGES = {
	start: 'It’s day one of our 30-day challenge! Remember to write in your journal!',
	middle: 'You’re halfway through our 30-day challenge! Keep going!',
	end: 'Congrats! You’ve made it to day 30 of our 30-day challenge. Make sure to complete your final entry.',
	gotBadge: 'Congratulations! You earned a new badge.',
	completed: 'Keep up the good work! Try to add an entry each day to keep the gratitude flowing.',
	common: [
		'Have you logged your gratitude(s) today? Don’t forget!',
		'It’s a great day to be grateful. Remember to write in your journal!',
		'What we appreciate, appreciates! Make sure to record your gratitude(s) today.',
		'Gratitude is a game changer. Remember to record yours today!',
		'Gratitude is a powerful tool for shifting perspective. Record yours today!',
		'Even a little bit of appreciation can change your whole day. Make sure to record yours.',
	],
}

export default defineComponent({
	components: { MessageCard },
	data () {
		return {
			message: '',
			isCookie: false,
			canShow: false,
			timer: null,
		}
	},
	mounted () {
		EventBus.$on('gratitudes-challenge-joined', this.getFirstMessage)
		EventBus.$on('gratitudes-challenge-got-badge', this.getBadgeMessage)
		EventBus.$on('gratitudes-challenge-completed', this.getCompletedMessage)
		EventBus.$on('no-unread-notices', this.allowDialogToShow)
		this.checkCookie()
		if (this.isCookie) {
			this.setTimer()
		} else {
			this.getMessage()
		}
	},
	beforeDestroy () {
		EventBus.$off('gratitudes-challenge-joined', this.getFirstMessage)
		EventBus.$off('gratitudes-challenge-got-badge', this.getBadgeMessage)
		EventBus.$off('gratitudes-challenge-completed', this.getCompletedMessage)
		EventBus.$off('no-unread-notices', this.allowDialogToShow)
		clearTimeout(this.timer)
	},
	computed: {
		doShowDialog () {
			if (!this.isOptedIn) { return false }
			if (this.isCookie) { return false }
			if (!this.canShow) { return false }
			return !!this.message
		},
		gratitudeChallengeConfig () {
			const configValue = this.user.config?.GratitudeChallenge?.ConfigValue
			if (!configValue?.length) { return }
			return configValue.find(entry => entry?.title === GRATITUDES_CHALLENGE_TITLE)
		},
		isOptedIn () {
			if (!this.gratitudeChallengeConfig) { return false }
			return moment().isBetween(
				this.gratitudeChallengeConfig.startDate, this.gratitudeChallengeConfig.endDate, 'day', '[]'
			)
		},
		hasBadge () {
			return this.gratitudeChallengeConfig?.hasBadge || false
		},
		dayOfChallenge () {
			if (!this.isOptedIn) { return } 
			return moment().diff(this.gratitudeChallengeConfig.startDate, 'days') + 1
		},
		cookieKey () {
			return `${GRATITUDES_CHALLENGE_COOKIE}-${this.user.AgentCode}-${this.user.email}`
		},
	},
	methods: {
		closeMessage () {
			this.message = ''
			this.canShow = false
			this.setCookie()
			this.setTimer()
		},
		checkCookie () {
            this.isCookie = !!Cookies.get(this.cookieKey)
        },
		setCookie () {
			const endOfDay = moment().endOf('day').toDate()
            Cookies.set(this.cookieKey, 'true', { expires: new Date(endOfDay) })
			this.isCookie = true
        },
		setTimer () {
			const now = moment().toDate()
			const tomorrow = moment().add(1, 'days').startOf('day').toDate()
			const delay = tomorrow.getTime() - now.getTime() + 1000
			this.timer = setTimeout(() => {
				this.allowDialogToShow()
				this.checkCookie()
				this.getMessage()
			}, delay)
        },
		getMessage () {
			if (!this.isOptedIn) { return }
			this.message = this.getRandomMessage(this.dayOfChallenge)
		},
		getFirstMessage () {
			this.canShow = true
			this.isCookie = false
			this.message = this.getRandomMessage(1)
		},
		getBadgeMessage () {
			this.canShow = true
			this.isCookie = false
			this.message = this.getRandomMessage('badge')
		},
		getCompletedMessage () {
			this.canShow = true
			this.isCookie = false
			this.message = this.getRandomMessage('completed')
		},
		getRandomMessage (day) {
			const min = 0
			const max = MESSAGES.common.length - 1
			const randomIndex = Math.floor(Math.random() * (max - min + 1) + min)
			switch (day) {
				case 1:
					return MESSAGES.start
				case 15:
					return MESSAGES.middle
				case 30:
					return MESSAGES.end
				case 'badge':
					return MESSAGES.gotBadge
				case 'completed':
					return MESSAGES.completed
				default:
					return MESSAGES.common[randomIndex]
			}
		},
		allowDialogToShow () {
			this.canShow = true
		}
	},
})
</script>
