<template>
    <div class="q-summit-guide pt-4">
        <QSummitSidebarGuideStep 
            v-for="(step, index) in steps" :key="step.title" :to="step.Url"
            :step="step"
            :index="index"
            :last="index !== steps.length - 1"
            :current-step="currentStep"
        />
    </div>
</template>

<script>
import QSummitSidebarGuideStep from "./guide/QSummitSidebarGuideStep.vue"

export default {
    components: { QSummitSidebarGuideStep },

    props: {
        steps: { type: Array, default: () => []},
        currentStep: { type: Object, required: true },
    },
}
</script>
