import { render, staticRenderFns } from "./QScoreCardSubmitted.vue?vue&type=template&id=6ca98caf&scoped=true&"
import script from "./QScoreCardSubmitted.vue?vue&type=script&lang=js&"
export * from "./QScoreCardSubmitted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca98caf",
  null
  
)

export default component.exports