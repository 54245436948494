<template>
    <div>
        <q-business-time-period-select v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat" align-left="true" :hideTimePeriodSwitch="hideTimePeriodSwitch"></q-business-time-period-select>
        <v-radio-group v-model="stat_type">
            <v-radio label="Total Agency" value="TotalAgency" v-if="show_total_agency"></v-radio>
            <v-radio label="Base Shop" value="Baseshop" v-if="show_base_shop"></v-radio>
            <v-radio label="Personal" value="PersonalProduction" v-if="show_personal_production"></v-radio>
        </v-radio-group>
        <q-agent-lookup label="Agent" v-if="(allowAgentLookup !== false && user.Agent.IsAgencyOwner) || hasRole(['Staff', 'SuperAdmin', 'Exec'])" v-model="agentLookup" :filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated'}"></q-agent-lookup>
    </div>
</template>
<script>
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QAgentLookup from '@/components/utils/QAgentLookup.vue';
export default {
    name: "QScoreCardFilters",
    props: ["value", "agent", "allowAgentLookup", "hideTimePeriodSwitch"],
    data: function() { // data internal to component (not available outside)
        return {
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            datePeriod: {},
            agentLookup: null,
            stat_type: "TotalAgency"
        }
    },
    mounted: function() {
        this.updateStatType()
        this.filters = this.value
    },
    computed: {
        show_total_agency: function() {
            return this.agent.LeadershipLevel != 'Agency Owner' || this.agent.AgentCode == "SFG0000001";
        },
        show_base_shop: function() {
            if (this.agent.AgentCode == "SFG0000001") {
                return false;
            }
            // KL and below shouldn't see base shop even if they have Virtual Assistant
            const keyLeaderAndBelow = ['Key Leader', 'Team Leader', 'Sales Representative'];
            if (keyLeaderAndBelow.includes(this.agent.LeadershipLevel)) {
                return false;
            }
            return this.agent.HasManagerAccess || this.agent.IsAgencyOwner
        },
        show_personal_production: function() {
            if (this.agent.AgentCode == "SFG0000001") return false
            return true
        }
    },
    methods: {
        updateFilters() {
            this.filters.startDate = this.datePeriod.startDateFormat;
            this.filters.endDate = this.datePeriod.endDateFormat;
            this.filters.timePeriod = this.datePeriod.period;
            this.filters.statType = this.stat_type;
            this.$emit('input', this.filters);
        },
        updateStatType() {
            if (this.agent.AgentCode == "SFG0000001") {
                this.stat_type = "TotalAgency"
                return
            }
            if (this.show_total_agency) {
                this.stat_type = "TotalAgency"
                return
            }
            if (this.show_base_shop) {
                this.stat_type = "Baseshop"
                return
            }
            this.stat_type = "PersonalProduction"
            return
        }
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.updateStatType)
        },
        agentLookup: function(newV) {
            this.$emit('agent', newV);
        },
        'stat_type': function(newV) {
            this.$nextTick(this.updateFilters)
        },
        'datePeriod': {
            deep: true,
            handler: function(newV) {
                this.$nextTick(this.updateFilters)
            }
        }
    },
    components: {
        QBusinessTimePeriodSelect,
        QAgentLookup
    }
}

</script>
<style scoped>
</style>
