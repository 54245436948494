<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				:ripple="false"
				class="q-app-bar-btn"
				:class="btnClass"
				to="/perform-dash/activate"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon>icon-perform-dash</v-icon>
			</v-btn>
		</template>
		<span>PerformDash</span>
	</v-tooltip>
</template>

<script>
export default {
	computed: {
        isMobile () {
			return this.$vuetify.breakpoint.xs
		},
		btnClass () {
			return [
				this.isMobile && 'q-app-bar-btn--mobile',
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.q-app-bar-btn {
	.icon-perform-dash::before {
		width: unset;
		font-size: 20px;
	}

	&--mobile {
		.icon-perform-dash::before {
			font-size: 16px;
		}
	}
}
</style>
