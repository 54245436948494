<template>
    <div>
        <v-snackbar top tile centered elevation="24" :timeout="color == 'error' ? null : 6000" :color="color" v-model="show_snackbar" vertical style="margin-top:10%;" max-width="400" min-width="400">
            <div v-html="text"></div>
            <div v-if="details">
                <v-btn text @click="show_details = !show_details">Details</v-btn>
                <div v-if="show_details" class="text-left" v-html="details">
                </div>
            </div>
            <v-btn text @click=" show_snackbar=false">
                Close
                <v-icon right small>fas fa-times-circle</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
export default {
    name: "QSnackbarNotices",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            show_snackbar: false,
            text: null,
            color: 'red',
            details: null,
            show_details: false
        }
    },
    mounted: function() {
        var g = this;
        EventBus.$on("showError", function(item) {
            if (g.show_snackbar) {
                g.text = g.text + "  " + item.text;
                g.details = g.details + "<hr>" + item.details;
            } else {
                g.text = item.text;
                g.details = item.details;
            }
            g.color = 'error';
            g.show_snackbar = true;
        });
        EventBus.$on("showSuccess", function(msg) {
            if (g.show_snackbar) {
                g.text = g.text + "  " + msg;
            } else {
                g.text = msg;
            }
            g.color = 'success';
            g.show_snackbar = true;
            g.details = null;
        });
        EventBus.$on("showWarning", function(msg) {
            if (g.show_snackbar) {
                g.text = g.text + "  " + msg;
            } else {
                g.text = msg;
            }
            g.color = 'warning';
            g.details = null;
            g.show_snackbar = true;
        });
        EventBus.$on("showInfo", function(msg) {
            if (g.show_snackbar && g.text) {
                g.text = g.text + "  " + msg;
            } else {
                g.text = msg;
            }
            g.color = 'info';
            g.details = null;
            g.show_snackbar = true;
        });
    },
    computed: {

    },
    methods: {

    },
    watch: {
        'show_snackbar': function() {
            this.show_details = false;
        }
    },
}

</script>
<style scoped>
</style>
