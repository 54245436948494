<template>
    <v-data-table :items="rows" :headers="columns" class="table-layout-fixed">
		<template v-for="column in columns" v-slot:[`item.${column.value}`]="{ item }">
			<p v-html="item[column.value]" style="white-space: normal; text-wrap: normal; word-wrap: break-word;" />
		</template>
	</v-data-table>
</template>

<script>
export default {
    name: "EditableTable",

    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    TableData: {
						rows: [],
						columns: []
					},
                }
            }
        }
    },

	computed: {
		rows() {
			return this.contentData.TableData.rows
		},
		columns() {
			return this.contentData.TableData.columns
		}
	},
}
</script>

<style lang="scss">
  .table-layout-fixed {
	table {
		table-layout: fixed;
	}
  }
</style>