import { getCurrentInstance } from "vue";

function getLightness(hexString: string) {
  // Remove the "#" character from the hex string
  hexString = hexString.replace("#", "");

  // Convert the hex string to RGB values
  const red = parseInt(hexString.substring(0, 2), 16);
  const green = parseInt(hexString.substring(2, 4), 16);
  const blue = parseInt(hexString.substring(4, 6), 16);

  // Convert the RGB values to HSL values
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const lightness = (max + min) / 2;

  return lightness;
}

export const useVuetify = () => {
  const vuetify = getCurrentInstance()!.proxy.$vuetify
  const theme = vuetify.theme;

  const getRawColor = (color: string) => {
    return vuetify.theme.currentTheme[color] as string || color;
  }

  const getTextColor = (background: string) => {
    return getLightness(getRawColor(background)) > 200 ? "black" : "white"
  }

  return { theme, getTextColor };
}