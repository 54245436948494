<template>
    <v-row class="px-3">
        <!--Quility Agents-->
        <v-card elevation="0" v-if="hasRole(['SalesRep', 'AgencyOwner', 'SuperAdmin']) && hasDivision('Corporate')" class="pa-5 mb-5">
            <h4>Quility Agents</h4>
            <p>Sign up using the code <strong>2021QUILITY</strong>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" v-clipboard="'2021QUILITY'" v-clipboard:success="handleCopyStatus">
                            <v-icon>far fa-copy</v-icon>
                        </v-btn>
                    </template>
                    <span>copy code</span>
                </v-tooltip>
                <br />
                <a href="https://shopquility.com/account?code=2021quility" target="_blank">Create an account</a>
            </p>
            <v-btn color="primary" large dark depressed href="https://shopquility.com/login" target="_blank">
                <v-icon dark>icon-q-shopping</v-icon> Visit The Shop
            </v-btn>
        </v-card>
        <!--/Quility Agents-->
        <!--Quility Corporate-->
        <v-card elevation="0" v-if="hasRole(['Staff', 'Exec','SuperAdmin'])" class="pa-5 mb-5">
            <h4>Quility Corporate</h4>
            <v-btn color="primary" large dark depressed href="https://shopquility.com/login" target="_blank">
                <v-icon dark class="py-3 pr-3">icon-q-shopping</v-icon> Visit The Shop
            </v-btn>
        </v-card>
        <!--/Quility Corporate-->
        <!--Symmetry Agents-->
        <v-card elevation="0" v-if="hasRole(['SalesRep', 'AgencyOwner', 'SuperAdmin' ]) && hasDivision('Field')" class="pa-5 mb-5">
            <h4>Symmetry Agents</h4>
            <v-btn color="primary" large dark depressed href="https://shopquility.com/login" target="_blank">
                <v-icon dark>icon-q-shopping</v-icon> Visit The Shop
            </v-btn>
        </v-card>
        <!--/Symmetry Agents-->
    </v-row>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        handleCopyStatus(status) {
            this.showInfo("Copied to clipboard")

        },
    },
    components: {}
}

</script>
