const routes = [{
        path: '/cms/',
        name: 'CMS',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/index.vue'),
        meta: {
            permission: ['manage:Content']
        }
    }, {
        path: '/cms/editor/:page_id?/:category_id?/:page_type?',
        name: 'CMSEditor',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/editPage.vue'),
        meta: {
            permission: ['manage:Content']
        }
    }, {
        path: '/cms/categories',
        name: 'Categories',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/views/categoriesView.vue'),
        meta: {
            permission: ['manage:Content']
        }
    }, {
        path: '/page/:page_slug',
        name: 'PageView',
        component: () => import( /* webpackChunkName: "cms_view" */ '../CMS/views/index.vue'),
    }, {
        path: '/cms/media/:image_id?',
        name: 'MediaLibrary',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/mediaLibrary.vue'),
        meta: {
            permission: ['manage:Content']
        }
    }, {
        path: '/cms/document/:document_id?',
        name: 'DocumentLibrary',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/documentLibrary.vue'),
        meta: {
            permission: ['manage:Content']
        }
    },
    {
        path: '/cms/document/view/:document_id?',
        name: 'DocumentLibrary',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/documentView.vue'),
    },
    {
        path: '/leadmedia/leadsheets/:agentCode/:leadCode/view',
        name: 'LeadsheetsView',
        component: () => import( /* webpackChunkName: "cms_leadsheets_view" */ '../CMS/editor/leadsheetsView.vue'),
    },
    {
        path: '/levelsofleadership',
        name: 'LevelsOfLeadership',
        component: () => import( /* webpackChunkName: "cms_view" */ '../CMS/views/agentProfiles.vue'),
    },
    {
        path: '/cms/leadership_profiles',
        name: 'EditLevelsOfLeadership',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/editAgentProfiles.vue'),
        meta: {
            permission: ['manage:Content']
        }
    },
    {
        path: '/corporate_staff',
        name: 'CorporateStaff',
        component: () => import( /* webpackChunkName: "cms_view" */ '../CMS/views/corporateProfiles.vue'),
    },
    {
        path: '/cms/corporate_profiles',
        name: 'EditCorporateStaff',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/editCorporateProfiles.vue'),
        meta: {
            permission: ['manage:Content']
        }
    },
    {
        path: '/cms/navigation',
        name: 'NavEditor',
        component: () => import( /* webpackChunkName: "cms" */ '../CMS/editor/editNavMenu.vue'),
        meta: {
            permission: ['manage:Content']
        }
    },
    {
        path: '/fontello-demo',
        name: 'FontelloDemo',
        component: () => import( /* webpackChunkName: "cms_view" */ '../assets/fontello-2e289b48/demo.vue'),
    },

    {
        path: '/cms/edit_carriers',
        name: 'CarrierPages',
        component: () => import( /* webpackChunkName: "cms" */ '../views/Admin/Carriers.vue'),
        meta: {
            permission: ['manage:Content']
        }
    },
    {
        path: '/cms/edit_carrier_products',
        name: 'CarrierProducts',
        component: () => import( /* webpackChunkName: "cms" */ '../views/Admin/Products.vue'),
        meta: {
            permission: ['manage:Content']
        }
    }
]

export default routes