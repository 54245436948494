const routes = [{
        path: '/my_stats/',
        name: 'MyStats',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/index.vue'),
        meta: {
            title: 'My Stats'
        },
    },
    {
        path: '/my_stats/',
        name: 'MyStats_SuperAdmin',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/SuperAdmin/index.vue'),
        meta: {
            title: 'My Stats Admin'
        },
    },
    {
        path: '/my_stats/',
        name: 'MyStats_Exec',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Exec/index.vue'),
        meta: {
            title: 'My Stats Executve Eddition'
        },
    },
    {
        name: "AgencyLeaderboards",
        path: '/my_stats/leaderboards',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Leaderboards.vue'),
        meta: {
            title: 'Leaderboards'
        },
    },
    {
        name: "EventLeaderboards",
        path: '/my_stats/event_leaderboards',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/EventsLeaderboards.vue'),
        meta: {
            title: 'Event Leaderboards'
        },
    },
    {
        name: "ScoreCards_NewBusiness",
        path: '/my_stats/scorecard/new_business/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_NewBusiness.vue'),
        meta: {
            title: 'Scorecards'
        },
    },
    {
        name: "ScoreCards_HQ",
        path: '/my_stats/scorecard/hq/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_HQ.vue'),
        meta: {
            title: 'HQ Scorecards'
        },
    },
    {
        name: "ScoreCards_Summit",
        path: '/my_stats/scorecard/summit/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_Summit.vue'),
        meta: {
            title: 'Summit Scorecards'
        },
    },
    {
        name: "Drilldown_HQ_Active",
        path: '/my_stats/drilldown/hq_active/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Active Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqActiveDrilldown'
            }
        }
    },
    {
        name: "Drilldown_HQ_Active_Staff",
        path: '/my_stats/drilldown/hq_active_staff/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Active Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqActiveStaffDrilldown'
            }
        }
    },
    {
        name: "Drilldown_HQ_Logins",
        path: '/my_stats/drilldown/hq_logins/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Sign Ins Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqLoginsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_HQ_Logins_Staff",
        path: '/my_stats/drilldown/hq_logins_staff/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Sign Ins Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqLoginsStaffDrilldown'
            }
        }
    },
    {
        name: "Drilldown_HQ_Promotions",
        path: '/my_stats/drilldown/hq_promotions/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Promotions Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqPromotionsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_HQ_Birthdays",
        path: '/my_stats/drilldown/hq_birthdays/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'HQ Birthday Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QHqBirthdaysDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Summit_Started",
        path: '/my_stats/drilldown/summit_started/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Summit Started Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QSummitStartedDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Phase_Completed",
        path: '/my_stats/drilldown/phase_completed/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Phase Completed Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QPhaseCompletedDrilldown'
            }
        }
    },
    {
        name: "ScoreCards_Leads",
        path: '/my_stats/scorecard/leads/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_Leads.vue'),
        meta: {
            title: 'Leads Scorecards'
        },
    },
    {
        name: "ScoreCards_Recruiting",
        path: '/my_stats/scorecard/recruiting/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_Recruiting.vue'),
        meta: {
            title: 'Recruiting Scorecards!'
        },
    },
    {
        name: "ScoreCards_Events",
        path: '/my_stats/scorecard/events/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/ScoreCards_Events.vue'),
        meta: {
            title: 'Event Scorecards'
        },
    },
    {
        name: "ScoreCards_QuilityRx",
        path: '/my_stats/scorecard/quility_rx/:agentcode?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/QScoreCards_QuilityRx.vue'),
        meta: {
            title: 'Quility Scorecards'
        },
    },
    {
        name: "Drilldown_Submitted",
        path: '/my_stats/drilldown/submitted/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Submitted Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QSubmittedDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Placed",
        path: '/my_stats/drilldown/placed/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Placed Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QPlacedDrilldown'
            }
        }
    },
    {
        name: "Drilldown_SME",
        path: '/my_stats/drilldown/sme/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'SME Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QSMEDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Resets",
        path: '/my_stats/drilldown/resets/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Resets Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QResetsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_ConservedPolicies",
        path: '/my_stats/drilldown/conserved_policies/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'ConservedPolicies Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QConservedPoliciesDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Closeratio",
        path: '/my_stats/drilldown/closeratio/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Close Ratio Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QCloseratioDrilldown'
            }
        }
    },
    {
        name: "Drilldown_UniqueWriters",
        path: '/my_stats/drilldown/uniquewriters/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Underwriters Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QUniqueWritersDrilldown'
            }
        }
    },
    {
        name: "Drilldown_ALeads",
        path: '/my_stats/drilldown/aleads/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'A-Leads drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QALeadsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_BonusLeads",
        path: '/my_stats/drilldown/bonus_leads/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Bonus Leads Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QBonusLeadsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_LeadsRequested",
        path: '/my_stats/drilldown/leads_requested/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Leads Requested Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QGmrRequestedLeadsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_UniqueLeadTakers",
        path: '/my_stats/drilldown/leadtakers/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Unique Lead Takers Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QUniqueLeadTakersDrilldown'
            }
        }
    },
    {
        name: "Drilldown_TotalRecruits",
        path: '/my_stats/drilldown/totalrecruits/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Total Recruits Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QTotalRecruitsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_NewRecruits",
        path: '/my_stats/drilldown/newrecruits/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'New Recruits Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QNewRecruitsDrilldown'
            }
        }
    }, {
        name: "Drilldown_NewWriters",
        path: '/my_stats/drilldown/newrecruits/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'New Writers Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QNewWritersDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Builders",
        path: '/my_stats/drilldown/unique_builders/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Builders Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QUniqueBuildersDrilldown'
            }
        }
    },
    {
        name: "Drilldown_Events",
        path: '/my_stats/drilldown/events/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Events Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QRegistrationsDrilldown'
            }
        }
    },
    {
        name: "Drilldown_QuilityRxCardsSent",
        path: '/my_stats/drilldown/quility_rx_cards_sent/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Quility Rx Cards Sent Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QuilityRxCardsSentDrilldown'
            }
        }
    },
    {
        name: "Drilldown_QuilityRxUsedCodes",
        path: '/my_stats/drilldown/quility_rx_used_codes/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Quility Rx Used Codes Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QuilityRxCodesUsedDrilldown'
            }
        }
    },
    {
        name: "Drilldown_QuilityRxCompensable",
        path: '/my_stats/drilldown/quility_rx_compensable/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Quility Rx Compensable Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QuilityRxCompensableDrilldown'
            }
        }
    },
    {
        name: "Drilldown_QuilityOnboardingPortalRecruitingStatus",
        path: '/my_stats/drilldown/quility_onboarding_portal_recruiting_status/:agent_code?',
        component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/Drilldowns/DrilldownView.vue'),
        meta: {
            title: 'Quility Onboarding Recruiting Status Drilldown'
        },
        props: function(route) {
            return {
                ...route.params,
                type: 'QOnboardingRecruitingStatusDrilldown'
            }
        }
    },
	{
        name: 'PerformDash',
        path: '/perform-dash/:dashboard',
        component: () => import( /* webpackChunkName: "perform_dash" */ '../views/PerformDash/PerformDash.vue'),
		meta: {
            title: 'Performance Dashboard'
        },
    },
	{
        path: '/perform-dash',
        redirect: '/perform-dash/activate',
    },
    {
        name: 'BonusReport',
        path: '/bonus-report',
        component: () => import( /* webpackChunkName: "bonus-report" */ '../modules/BonusReport/views/BonusReport.vue'),
        meta: {
            title: 'Bonus Report'
		},
	},
    {
        name: 'BirdDogDashboard',
        path: '/bird-dog-dashboard',
        component: () => import( /* webpackChunkName: "bird-dog-bonus" */ '../modules/BirdDogDashboard/views/BirdDogDashboard.vue'),
        meta: {
            title: 'Bird-Dog Dashboard'
		},
	},

    //removed with refactor, no longer needed.
    //{
    //   name: "AgencyDownline",
    //    path: '/my_stats/downline',
    //    component: () => import( /* webpackChunkName: "stats" */ '../views/MyStats/DownlineProduction.vue'),
    //},
]

export default routes
