<template>
    <v-expansion-panel-header class="q-summit-sidebar-accordion-header" :style="{ background: color }">
        <div class="q-summit-sidebar-accordion-header__bg">
            <div :is="bg" />
        </div>
        
        <slot />

        <template #actions>
            <ExpandHeaderIcon class="q-summit-sidebar-accordion-header__expand-icon" />
        </template>
    </v-expansion-panel-header>
</template>

<script>
import * as backgrounds from './headers/backgrounds'
import ExpandHeaderIcon from './ExpandHeaderIcon.vue'

export default {
    components: { ExpandHeaderIcon },

    props: {
        category: {
            type: String,
            required: true
        },
        color: {
            type: String
        }
    },

    computed: {
        bg() { return backgrounds[this.category.toLowerCase().replaceAll(/ /g, '')] },
    }
}
</script>

<style lang="scss">
.q-summit-sidebar-accordion-header {
    position: relative;
    padding: 0 !important;
    z-index: 0;

    &__bg {
        position: relative;
        height: 92px;
        width: 300px;

        & > * {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 92px;
        }
    }

    &.v-expansion-panel-header {
        .v-expansion-panel-header__icon  {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) translateY(6px);
            z-index: 999;
            padding: 16px;
            box-sizing: border-box;
            .q-summit-sidebar-accordion-header__expand-icon {
                transform: rotate(-90deg);
                transition: all .2s ease-in-out;
            }
        }

        &--active {
            .v-expansion-panel-header__icon {
                .q-summit-sidebar-accordion-header__expand-icon {
                    transform: rotate(0);
                }
            }
        }
    }
}
</style>