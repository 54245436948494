<template>
    <div>
        <h3 class="with-short-rule">News <v-progress-circular small v-if="loading" indeterminate color="primary"></v-progress-circular>
        </h3>
        <v-list dense>
            <v-list-item-group color="primary">
                <template v-for="(event, key) in events">
                    <v-list-item @click="resourceLink(event)" target="_tab" two-line>
                        <v-list-item-action>
                            <v-icon small color="primary">icon-q-news-select</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4>{{ event.title }}</h4>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{formatDateCalendar(event.created_at)}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
        <div class="text-right">
            <v-btn depressed color="primary" :to="{name:'News'}">See All</v-btn>
        </div>
    </div>
</template>
<script>
import NewsPage from '@/store/Models/NewsPage'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['type'],
    data: function() {
        return {

        }
    },
    mounted: function() {
        //this.loadData()
    },
    computed: {
        events: function() {
            var g = this
            return NewsPage.query()
                .where('division', g.user.currentSetDivision)
                .where('category_id', 15)
                .orderBy('order')
                .orderBy('created_at', 'desc')
                .limit(3)
                .get()
        },
        loading: function() {
            return this.$store.state.entities.news_page.loading;
        }
    },
    methods: {
        loadData: function(opt) {
            var d = new Date()
            QuilityAPI.appendPages({ 'category_id': 15, 'itemsPerPage': 3, 'sortBy': 'created_at', 'sortDesc': true }, {}, 'NewsPage');
        },
        resourceLink: function(item) {
            if (item.external_url && item.external_url != "internal") {
                if (item.external_url.indexOf('quility.com') > -1) {
                    document.location = item.external_url
                } else {
                    window.open(item.external_url, '_tab');
                }
            } else {
                this.$router.push("/page/" + item.slug)
            }
        }
    }
}

</script>
