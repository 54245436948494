const routes = [{
        path: '/fml/',
        name: 'FML',
        component: () => import( /* webpackChunkName: "cms" */ '../FML/editor/index.vue'),
        meta: {
            permission: ['manage:Forms']
        }
    }, {
        path: '/form/test',
        name: 'FormTest',
        component: () => import( /* webpackChunkName: "cms_view" */ '../views/content/TestForm.vue'),
    }, {
        path: '/fml/editor/:form_id?/:category_id?/:page_type?',
        name: 'FMLEditor',
        component: () => import( /* webpackChunkName: "cms" */ '../FML/editor/editForm.vue'),
        meta: {
            permission: ['manage:Forms']
        }
    }, {
        path: '/form/:form_slug',
        name: 'FormView',
        component: () => import( /* webpackChunkName: "cms_view" */ '../FML/views/index.vue'),
    }, {
        path: '/quiz/:form_slug',
        name: 'QuizView',
        component: () => import( /* webpackChunkName: "cms_view" */ '../FML/views/index-quiz'),
    }, {
        path: '/form/:form_slug/data',
        name: 'FormData',
        component: () => import( /* webpackChunkName: "cms_view" */ '../FML/data/index.vue'),
    }, {
        path: '/form/:form_slug/response/:form_response_id',
        name: 'FormResponse',
        component: () => import( /* webpackChunkName: "cms_view" */ '../FML/views/index.vue'),
    }, {
        path: '/newbusiness/fif-form/',
        name: 'FIF Form',
        component: () => import( /* webpackChunkName: "cms_view" */ '../views/NewBusiness/FIF/FML-FinancialInformationForm.vue'),
    }, {
        path: '/newbusiness/fif-form/data',
        name: 'FIF Form',
        component: () => import( /* webpackChunkName: "cms_view" */ '../views/NewBusiness/FIF/FML-FinancialInformationFormData.vue'),
    },



]

export default routes
