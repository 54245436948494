import { Model } from '@vuex-orm/core'

export default class Leaderboard extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'leaderboard'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    ////Rank,Agent,Apps,APV,UplineMgr,AgencyManager,KeyLeader,MasterAgency
    static fields() {
        return {
            id: this.attr(null),
            Rank: this.attr(''),
            Agent: this.attr(''),
            Apps: this.attr(''),
            APV: this.attr(''),
            UplineMgr: this.attr(''),
            AgencyManager: this.attr(''),
            KeyLeader: this.attr(''),
            MasterAgency: this.attr('')
        }
    }
}