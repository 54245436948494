export const resourceMap = {
    'opt!': 'Q2ASSO_Opt',
    switchboard: 'Q2ASSO_Switchboard',
    navigator: 'Q2ASSO_Navigator',
    ipipeline: 'Q2ASSO_iPipeline',
    formpipe: 'Q2ASSO_Formpipe',
    quilitytech: 'Q2ASSO_QuilityTech',
    quilityu: 'Q2ASSO_QuilityU',
    onboardingportal: 'Q2ASSO_OBP',
    virtualmentor: 'Q2ASSO_VirtualMentor',
    ninja: 'Q2ASSO_Ninja',
    quilitystore: 'Q2ASSO_QuilityStore',
    iulpathfinder: 'Q2ASSO_IULPathfinder',
    'quility.com': 'Q2ASSO_Quility.com',
};
