<template>
    <div class="q-search-filters">
		<v-checkbox v-model="mValue.Agent" class="mt-0 pt-0" label="Agents" :color="color" :disabled="disabled" hide-details />
		<v-checkbox v-model="mValue.AgentDocument" class="mt-0 pt-0" label="Documents" :color="color" :disabled="disabled" hide-details />
		<v-checkbox v-model="mValue.Page" class="mt-0 pt-0" label="HQ Pages" :color="color" :disabled="disabled" hide-details />
    </div>
</template>

<script>
export default {
    props: {
		value: {
			type: Object,
			required: true,
		},
		color: {
			type: String,
			default: '#46C3B2',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
    },
}
</script>

<style lang="scss">
.q-search-filters {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem 1rem;
}
</style>
