const routes = [{
    path: '/admin/storedproc/AgentUplineCarrier',
    name: 'AgentUplineCarrier',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/StoredProcedures/AgentUplineCarrier.vue'),
    meta: {
        title: 'Agent Up-line Carrier'
    },
}, {
    path: '/admin/storedproc/SnapshotAgents',
    name: 'SnapshotAgents',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/StoredProcedures/SnapshotAgents.vue'),
    meta: {
        title: 'Agent Snapshot'
    },
}, {
    path: '/admin/storedproc/SnapshotCarrier',
    name: 'SnapshotCarrier',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/StoredProcedures/SnapshotCarrier.vue'),
    meta: {
        title: 'Carrier Snapshot'
    },
}, {
    path: '/admin/storedproc/SnapshotAgencyLeaderboard',
    name: 'SnapshotAgencyLeaderboard',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/StoredProcedures/SnapshotAgencyLeaderboard.vue'),
    meta: {
        title: 'Agency Leaderboard Snapshot'
    },
}]

export default routes