<template>
    <v-card max-height="70vh" style="background-color:transparent;" class=" elevation-0">
        <v-card-title>
            <h3 class="text-left with-short-rule">End-User License Agreement for the Quility Portal</h3>
        </v-card-title>
        <v-card-text>
            <div class="scroll elevation-0 pa-4" style="height: calc(60vh - 175px); text-align:left; border:#ccc 1px solid; ">
                <page slug="software-agreement"></page>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn tile depressed color="primary" large @click="acceptSoftwareAgreement">Accept</v-btn>
            <v-btn tile depressed @click="rejectSoftwareAgreement">Reject</v-btn>
            <v-spacer />
        </v-card-actions>
        <v-progress-linear indeterminate v-if="config_loading"></v-progress-linear>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/CMS/views/index.vue'
import ReleaseMeta from '@/ReleaseMeta.json'

export default {
    props: [],
    data() {
        return {
            config_loading: false,
        }
    },
    mounted: function() {},
    methods: {
        'acceptSoftwareAgreement': function() {
            var g = this
            g.config_loading = true
            this.updateConfig("AcceptedSoftwareAgreement " + ReleaseMeta.Version, true, "boolean").then(function(json) {
                g.config_loading = false
            })
        },
        'rejectSoftwareAgreement': function() {
            var g = this
            g.config_loading = true
            this.updateConfig("AcceptedSoftwareAgreement " + ReleaseMeta.Version, false, "boolean").then(function(json) {
                g.config_loading = false
                g.$auth.logout({
                    returnTo: window.location.origin
                });
            })
        }
    },
    components: {
        Page,
    }
}

</script>
