<template>
    <div>
        <QScoreCard
            :agent-code="agentCode"
            :filters="filters"
            key="QScoreCardSME"
            title="SME"
            api-endpoint="sme"
            drill-downLabel="Agent Breakdown"
            drill-down-route="Drilldown_SME"
            card-style="production"
            format="Currency"
        >
            Note: SME stats are only available after the end of the month - updated once a month.
        </QScoreCard>
    </div>
</template>

<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardSME",
    props: ["agentCode", "filters"],
    components: {
        QScoreCard,
    },
}
</script>

<style scoped></style>
