<template>
    <v-dialog :value="value" width="650" @input="$emit('input', value)">
        <v-card class="pa-6 text-center" min-height="">
            <v-card-title class="justify-center flex-column">
                <v-img :src="settings.image" contain style="width: 8rem" class="mb-4" />
                <h4>{{ settings.month }} Pending Policies Update</h4>
                <div>You have <span :class="{ 'font-weight-bold primary--text': !totalValue }">{{ settings.num_pending_policies }} pending policies</span> this month.</div>
                <div class="mb-8">
                    <span v-if="totalValue" class="mr-2">That's <span class="font-weight-bold primary--text">{{ totalValue }}</span> on the table.</span>
                    <span>Let's get those placed!</span>
                </div>
                <div class="d-flex" style="width: 100%">
                    <v-btn color="primary" text @click="$emit('input', false)">
                        Close
                    </v-btn>
                    <v-btn
                        :to="{ name: 'Pending Policy' }"
                        color="primary"
                        class="ml-auto"
                        @click="$emit('input', false)"
                    >
                        <div class="flex items-center">
                            View Now
                            <v-icon>icon-q-right-arrow</v-icon>
                        </div>
                    </v-btn>
                </div>
            </v-card-title>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "QNotificationMenuPoliciesDialog",
    props: {
        value: { type: Boolean, required: true },
        settings: { type: Object, required: true },
    },
    computed: {
        totalValue() {
            if (this.settings.total_policy_value) {
                return this.settings.total_policy_value.toLocaleString("en-US", {style:"currency", currency:"USD"});
            }

            return ""
        },
    },
}
</script>

<style lang="scss" scoped>
.emphasize {
    font-weight: 600;
}
</style>
