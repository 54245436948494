<template>
    <div class="q-notification-menu">
        <v-menu v-model="isNotificationMenuOpened" offset-y transition="slide-y-transition" content-class="q-notification-menu__content">
            <template v-slot:activator="{ on, attrs }">
				<v-btn
					depressed
					:ripple="false"
					retain-focus-on-click
					class="q-app-bar-btn"
					:class="btnClass"
					v-bind="attrs"
					v-on="on"
				>
					<v-badge v-if="totalAlerts > 0" :content="roundedTotalAlerts" color="#DE3F35" light :offset-x="12">
						<v-icon>icon-alert-q</v-icon>
					</v-badge>
					<v-icon v-else>icon-alert-q</v-icon>
				</v-btn>
            </template>

            <v-list class="q-notification-menu__list">
                <v-list-item :to="{ name: 'Pending Policy' }" :class="pendingPolicyCount > 0 && 'list-item__active-alert'">
                    <v-list-item-icon>
                        <v-icon>icon-q-currency-dollar-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ pendingPolicyTitle }}</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{ name: 'SupportTickets' }" :class="ticketNotices > 0 && 'list-item__active-alert'">
                    <v-list-item-icon>
                        <v-icon>icon-q-support</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ supportTicketsTitle }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="showNotificationHistoryMenuItem" :to="{ name: 'Notices' }" :class="unreadMessageCount > 0 && 'list-item__active-alert'">
                    <v-list-item-icon>
                        <v-icon>icon-q-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ messagesTitle }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="user.Agent.Flanking.length > 0" :to="{ name: 'User Profile' }" class="warning">
                    <v-list-item-icon>
                        <v-icon>fas fa-exclamation-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        <span>1 Flanking Alert</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <QUnreadNotices v-if="unreadMessageCount > 0 && !isDemoMode" />

        <QGratitudesJournalNotices />

        <QNotificationMenuPoliciesAlert
            v-if="pendingPolicyCount > 0"
            v-model="isPoliciesAlertVisible"
            :unread-notification-count="getRoundedCountText(pendingPolicyCount)"
            :style="`top: ${isMessageAlertVisible ? 152 : 64}px;`"
        />

        <QNotificationMenuMessagesAlert
            v-if="unreadMessageCount > 0"
            v-model="isMessageAlertVisible"
            :unread-message-count="getRoundedCountText(unreadMessageCount)"
            style="top: 64px;"
        />

        <QNotificationMenuPoliciesDialog
            v-model="isPoliciesPopUpAlertVisible"
            :settings="policiesAlertPopUpSettings"
        />
    </div>
</template>

<script>
import moment from 'moment'
import { onUnmounted, ref } from "vue";
import { EventBus } from "@/event-bus";
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { getUnreadNoticesCount } from '@/store/API/PopupAcknowledgements'
import SupportTicket from '@/store/Models/SupportTicket'
import QAdminNotices from '@/components/utils/QAdminNotices.vue'
import Page from '@/CMS/views/index.vue'
import QUnreadNotices from '@/components/notices/QUnreadNotices.vue'
import QGratitudesJournalNotices from '@/views/Gratitudes/QGratitudesJournalNotices.vue'
import QNotificationMenuMessagesAlert from './QNotificationMenuMessagesAlert.vue'
import QNotificationMenuPoliciesAlert from './QNotificationMenuPoliciesAlert.vue'
import QNotificationMenuPoliciesDialog from './QNotificationMenuPoliciesDialog.vue'
import { useLocalStorageItem } from '@/composables/useLocalStorageItem'

const isItToday = (date) => new Date().toDateString() === date.toDateString()
const isItCurrentMonth = (date) => new Date().getMonth() === date.getMonth() && new Date().getFullYear() === date.getFullYear()

export default {
    name: "QNotificationMenu",

    components: {
        QAdminNotices,
        QUnreadNotices,
        QGratitudesJournalNotices,
        QNotificationMenuMessagesAlert,
        QNotificationMenuPoliciesAlert,
        QNotificationMenuPoliciesDialog,
        Page,
    },

    setup() {
        const unreadMessageCount = ref(0);

        const lastMenuOpening = useLocalStorageItem('q:notices:last-menu-opening', new Date(0))
        const lastMessagesAlertOpening = useLocalStorageItem('q:notices:last-messages-alert-opening', new Date(0))
        const lastPoliciesAlertOpening = useLocalStorageItem('q:notices:last-policies-alert-opening', new Date(0))
        const lastPoliciesPopUpAlertOpening = useLocalStorageItem('q:notices:last-policies-popup-alert-opening', new Date(0))

        const refreshUnreadMessageCount = async () => {
            unreadMessageCount.value = await getUnreadNoticesCount()

            if (!unreadMessageCount.value) {
                EventBus.$emit('no-unread-notices')
            }
        };

        refreshUnreadMessageCount();

        EventBus.$on('update:message', refreshUnreadMessageCount);
        onUnmounted(() => {
            EventBus.$off('update:message', refreshUnreadMessageCount);
        });

        return {
            lastMenuOpening,
            lastMessagesAlertOpening,
            lastPoliciesAlertOpening,
            lastPoliciesPopUpAlertOpening,
            unreadMessageCount,
        };
    },

    data() {
        return {
            isNotificationMenuOpened: false,
            isMessageAlertVisible: false,
            isPoliciesAlertVisible: false,
            isPoliciesPopUpAlertVisible: false,
            pendingPolicyCount: 0,
            pendingPolicyNoticeData: {},
            policiesAlertPopUpImage: null,
        };
    },

    created() {
        this.getPendingPolicyCount()

        if ((!isItCurrentMonth(this.lastPoliciesPopUpAlertOpening) || this.isImpersonating) && !this.hasRole(['SuperAdmin', 'Staff'])) {
            this.getMonthlyPendingPolicyCount()

            if (this.isImpersonating) { return }

            this.lastPoliciesPopUpAlertOpening = new Date()
        }
    },

    computed: {
        isMobile () {
			return this.$vuetify.breakpoint.xs
		},
		btnClass () {
			return [
				this.isMobile && 'q-app-bar-btn--mobile',
				this.isNotificationMenuOpened && 'q-app-bar-btn--active',
				this.hasNewAlert && 'q-app-bar-btn--accent',
			]
		},
        ticketNotices() {
            return SupportTicket.query()
                .where('status', (value) => (value == 'solved' || value == 'pending'))
                .count();
        },
        totalAlerts () {
            const flankingCount = this.user.Agent.Flanking.length ? 1 : 0;
            return this.pendingPolicyCount + this.ticketNotices + this.unreadMessageCount + flankingCount
        },
        roundedTotalAlerts () {
			return this.getRoundedCountText(this.totalAlerts)
        },
        showNotificationHistoryMenuItem() {
            return this.user.popups_history && this.user.popups_history.length > 0
        },
		hasNewAlert () {
			const wasMenuOpened = isItToday(this.lastMenuOpening)
            return this.totalAlerts > 0 && !wasMenuOpened
		},
        pendingPolicyTitle() {
            const text = this.pendingPolicyCount === 1 ? 'Policy' : 'Policies';
            const countText = this.getRoundedCountText(this.pendingPolicyCount);

            return countText + ' Pending ' + text;
        },
        supportTicketsTitle() {
            const text = this.ticketNotices === 1 ? '' : 's';
            const countText = this.getRoundedCountText(this.ticketNotices);

            return countText + ' Support Ticket' + text;
        },
        messagesTitle() {
            const countText = this.getRoundedCountText(this.unreadMessageCount);

            return countText + ' Messages';
        },
        policiesAlertPopUpSettings() {
            return {
                ...this.pendingPolicyNoticeData,
                month: new Date().toLocaleString('default', { month: 'long' }),
                image: this.policiesAlertPopUpImage,
            };
        },
    },

    methods: {
        getPendingPolicyCount() {
            QuilityAPI.getAgentPolicyCountSinceLastVisit(this.user.AgentCode)
                .then((json) => {
                    this.pendingPolicyCount = json.Count;
                });
        },
        getMonthlyPendingPolicyCount() {
            const imageIndex = new Date().getMonth() % 4 + 1
            this.policiesAlertPopUpImage = require(`@/assets/images/pending-policies-notification/pending-policies-icon-${imageIndex}.svg`)

            const date = moment().format('YYYY-MM-DD')
            QuilityAPI.getAgentPolicyMonthlyCount(date)
                .then((json) => {
                    this.pendingPolicyNoticeData = json?.data;

                    if (this.pendingPolicyNoticeData.num_pending_policies) {
                        this.isPoliciesPopUpAlertVisible = true
                    }
                });
        },
        getRoundedCountText(count) {
            return count > 10 ? '10+' : count.toString();
        },
    },

    watch: {
        unreadMessageCount(newValue) {
            if (newValue > 0 && !isItToday(this.lastMessagesAlertOpening)) {
                this.lastMessagesAlertOpening = new Date()
                this.isMessageAlertVisible = true
            }
        },
        pendingPolicyCount(newValue) {
            if (newValue > 0 && !isItToday(this.lastPoliciesAlertOpening)) {
                this.lastPoliciesAlertOpening = new Date()
                this.isPoliciesAlertVisible = true
            }
        },
        isNotificationMenuOpened(newValue) {
            if (newValue && this.totalAlerts > 0) {
                this.lastMenuOpening = new Date()
            }
        },
    },
}
</script>

<style lang="scss">
.q-notification-menu__content.v-menu__content {
	border-radius: 0 0 8px 8px;
}
</style>

<style lang="scss" scoped>
.q-notification-menu {
	.q-app-bar-btn {
		::v-deep .v-badge .v-badge__badge {
			border-radius: 6px !important;
			font-weight: 600;
		}

		.v-icon.v-icon {
			font-size: 24px !important;
		}

		&--mobile {
			.v-icon.v-icon {
				font-size: 20px !important;
			}
		}
	}

    &__list {
		padding: 0;

		.v-list-item {
			color: rgba(0, 0, 0, 0.87) !important;
            font-weight: 500;

			.v-icon {
				color: rgba(0, 0, 0, 0.4);
			}
		}

		.v-list-item__icon:first-child {
			margin-right: 16px;
		}

		.v-list-item.list-item__active-alert:not(.v-list-item--active) {
			color: #0083ea !important;
            font-weight: 600;

			.v-icon {
				color: inherit;
			}
		}

        .v-list-item--active {			
			background-color: #8D8D8D !important;

            &:before {
                background-color: transparent !important;
            }

			&:not(:hover) {
				color: white !important;
			}

			.v-icon {
				color: inherit;
			}
        }

        .v-list-item:hover {
            background-color: #E4E4E4 !important;
            text-decoration: none !important;

			&:before {
				opacity: 0;
			}

			.v-list-item__title {
				text-decoration: underline;
			}

			.v-icon {
				color: inherit;
			}
        }
    }
}
</style>
