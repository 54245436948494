<template>
    <QResourceMenu :links="links" :loading="loading" :error="error" />
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import QResourceMenu from './QResourceMenu.vue'

export default {
    name: 'QResourceMenuCss',
    components: {
        QResourceMenu,
    },
	data () {
		return {
			links: [],
			loading: false,
			error: false,
		}
	},
	created () {
		this.loadLinks()
	},
	methods: {
		async loadLinks () {
			this.setLoadingStatus(true, false)
			const res = await QuilityAPI.getAppMenus()
			if (res.error) {
				this.links = []
				this.setLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading App Menu links.`, res.message)
				console.error(res.error)
			} else {
				this.links = res
				this.setLoadingStatus(false, false)
			}
		},
		setLoadingStatus(loading, error) {
			this.loading = loading
			this.error = error
		},
	},
}
</script>
