<template>
    <div @click="disabled || closeDrawer()">
        <router-link class="q-summit-guide-step" :to="step.Url" :is="!disabled ? 'router-link': 'span'">
            <div class="q-summit-guide-step__map">
                <div class="q-summit-guide-marks">
                    <QSummitSidebarGuideCircle :right="index % 2 == 0" :color="circleColor">
                        <QSummitSidebarGuideIcon v-if="type !== 'Step'" :url="step.StepIcon" :step="step" :disabled="disabled" />
                    </QSummitSidebarGuideCircle>
                    <QSummitSidebarGuideLine v-if="last" :right="index % 2 !== 0" />
                </div>
            </div>
            <div class="q-summit-guide-step__content text--primary">
                <QSummitSidebarGuideText :title="title" :description="description" :isComplete="step.Completed" :inProgress="isInProgress" :color="color" :disabled="disabled" :type="type" />
            </div>
        </router-link>        
    </div>
</template>
<script>
import { store } from '../store/sidebar-store'
import QSummitSidebarGuideCircle from "./QSummitSidebarGuideCircle.vue"
import QSummitSidebarGuideLine from "./QSummitSidebarGuideLine.vue"
import QSummitSidebarGuideText from "./QSummitSidebarGuideText.vue"
import QSummitSidebarGuideIcon from "./QSummitSidebarGuideIcon.vue"

const STEP_TYPE = {
    Milestone: 'Milestone',
    Checkpoint: 'Checkpoint',
    Step: 'Step',
}

const STEP_COLORS = {
    Milestone: 'q_support_1',
    Checkpoint: 'q_leads_1',
    Step: 'q_green_1'
}

export default {
    components: { QSummitSidebarGuideCircle, QSummitSidebarGuideLine, QSummitSidebarGuideText, QSummitSidebarGuideIcon },

    props: {
        step: { type: Object, required: true },
        index: { type: Number, required: true },
        last: { type: Boolean, required: true },
        currentStep: { type: Object, required: true },
    },
    methods: {
        closeDrawer: function() {
            store.doShowSummitSidebar = false
        },
    },
    computed: {
        type() { return this.step.StepType },
        description() { return this.step.StepTitle },
        isCompleted() { return this.step.Completed },
        isOptional() { return Number(this.step.OptionalInd) === 1 },
        isInProgress() { return this.step.CurrentStepInd && !this.isCompleted },
        color() { return STEP_COLORS[this.type] || 'q_green_1' },


        disabled() {
            if (this.isInProgress) { return false }

            const afterCurrent = Number(this.currentStep.StepOrder) < Number(this.step.StepOrder)

            if (afterCurrent) { return true }

            if (this.isOptional) {
                return false
            }

            return false;
        },


        title() {
            if (this.step.StepType === STEP_TYPE.Milestone) {
                return `Milestone`
            }

            if (this.step.StepType === STEP_TYPE.Checkpoint) {
                return `Mentor checkpoint`
            }

            if (this.isOptional) {
                return 'Challenge step'
            }

            return `Step ${this.index + 1}`
        },

        circleColor() {
            if (this.type === STEP_TYPE.Step) {
                if (this.disabled) { return "#ADADAD" }

                return '#015851'
            } else {
                return 'white'
            }
        },
    },
}
</script>
<style lang="scss">
.q-summit-guide-step {
    display: flex;

    .vuetify-fix-icon.vuetify-fix-icon {
        &--small {
            font-size: 8px !important;
        }

        font-size: 12px !important;
    }

    &__map {
        padding: 0 24px;
        width: 100px;
        display: flex;
        position: relative;
        height: 60px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .q-summit-guide-marks {
        display: flex;
        width: 100px;
        position: relative;
        z-index: 0;

        .q-summit-guide-circle {
            position: absolute;
            top: 50%;

            transform: translateY(-50%) translateX(-50%);

            &--right {
                transform: translateY(-50%) translateX(50%);
            }
        }

        .q-summit-sidebar-guide-line {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: -1;
            box-sizing: border-box;
            top: 50%;
        }
    }

    &__step-index {
        display: flex;
        align-items: center;
    }

    &__title {
        font: normal normal normal 13px/16px Montserrat;
        margin: 0;
    }
}

a {
    text-decoration: none !important;
}
</style>