<template>
    <div
        class="q-summit-sidebar-guide-line"
        :class="{'q-summit-sidebar-guide-line--right': right }"
    >
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            <g>
                <line y2="0" x2="100%" y1="100%" x1="0" stroke-dasharray="8, 10" stroke="#000" fill="none"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: { 
        right: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss" scoped>
    .q-summit-sidebar-guide-line, svg{
        width: 100%;
        height: 100%;
        
        &--right {
            transform: scaleX(-1);
        }

        line {
            width: 100%;
            height: 100%;
        }
    }
</style>