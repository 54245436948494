<template>
    <v-list-item
		class="navigation-list-item"
		exact-active-class="navigation-list-item__item-active"
		v-bind="routerLinkProps"
		@click.stop="clicked"
	>
        <v-list-item-action>
            <v-badge v-if="badgeContent" :color="badgeColor" dot>
                <v-icon small>{{ icon }}</v-icon>
            </v-badge>
            <v-icon v-else small>{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
				<template v-if="label">{{ label }}</template>
				<slot v-else />
                <v-chip v-if="badgeContent" :color="badgeColor" dark x-small pill class="ml-2">{{ badgeContent }}</v-chip>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
	props: {
		label: { type: String },
		icon: { type: String },
		route: { type: [String, Object] },
		path: { type: String },
		href: { type: String },
		click: { type: Function },
		badgeContent: { type: [String, Number] },
		badgeColor: { type: String },
	},
	computed: {
		routerLinkProps() {
			if (this.href) {
				return {
					href: this.href,
					target: '_blank',
				}
			}

			if (this.route) {
				if (typeof this.route === 'string') {
					// Sometimes route can be just a path, not name
					if (this.route.startsWith('/')) {
						return {
							to: this.route,
						}
					}

					return {
						to: { name: this.route },
					}
				}

				return {
					to: this.route,
				}
			}

			if (this.path) {
				if (this.path.startsWith('http')) {
					return {
						href: this.path,
						target: '_blank',
					}
				}

				return {
					to: this.path,
				}
			}

			console.error('No route/path/href provided to MenuItem')
			return {}
		},
	},
	methods: {
		clicked () {
			if (typeof this.click === 'function') {
                this.click()
            }
		},
	},
}
</script>
