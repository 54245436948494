import moment from 'moment'

// this is used to quickly
// look up the difference
// between the given
// day of the week and
// either the previous saturday
// to get the start of the week
// or the current friday (relavtive to the date).

export let weekdiff = {
    'Monday': {
        'friday': 3,
        'saturday': 2,
    },
    'Tuesday': {
        'friday': 4,
        'saturday': 3
    },
    'Wednesday': {
        'friday': 5,
        'saturday': 4
    },
    'Thursday': {
        'friday': 6,
        'saturday': 5
    },
    'Friday': {
        'friday': 0,
        'saturday': 6
    },
    'Saturday': {
        'friday': 1,
        'saturday': 0
    },
    'Sunday': {
        'friday': 2,
        'saturday': 1
    }
}

/**
 * Used to determine if
 * the agent file a dispute
 * @returns true | false
 */

export function isDisputeTimePeriod() {
    const month = moment().month()
    const year = moment().year()
    const current = moment().valueOf()
    const now = new Date()
    const numDaysInMonth = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate()
    let thurCount = 0

    // default to december dates
    let start = moment().year(year).month(11).date(15).valueOf()
    let end = moment().year(year).month(11).date(17).hour(11).minute(59).second(59).millisecond(999).valueOf()

    if(month != 11) {
        for (let index = 1; index <= numDaysInMonth; index++) {
            const curDate = moment().date(index)

            if(curDate.month() == month && curDate.day() == 4){
                thurCount++
            }

            if(thurCount == 2) {
                start = curDate.clone().valueOf()
                end = curDate.clone().add(4, 'd').valueOf()

                break
            }
        }
    }

    return current >= start && current <= end
}