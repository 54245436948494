const routes = [{
        path: '/admin/component-guide',
        name: 'ComponentGuide',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/ComponentGuide.vue'),
        meta: {
            permission: ['SuperAdmin'],
            title: 'Component Guide'
        }
    },
    {
        path: '/admin/style-guide',
        name: 'StyleGuide',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/StyleGuide.vue'),
        meta: {
            permission: ['SuperAdmin'],
            title: 'Style Guide'
        }
    },
    {
        path: '/admin/route-list',
        name: 'RouteList',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/RouteList.vue'),
        meta: {
            permission: ['SuperAdmin'],
            title: 'Route List'
        }
    }, {
        path: '/admin/api-test',
        name: 'API',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/APITest.vue'),
    }, {
        name: "AdminCreateAgent",
        path: '/agents/new_agent',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/NewAgent.vue'),
        meta: {
            permission: ['SuperAdmin', "Exec", "Staff"],
            title: 'Create a New Agent'
        },
    }, {
        path: '/admin/sent-messages/',
        name: 'PopupNoticeAdmin',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/PopupNotices/index.vue'),
        meta: {
            permission: ['SuperAdmin', 'AgencyOwner', "Exec", 'Staff'],
            title: 'Sent Message'
        }
    }, {
        path: '/admin/sent-messages/:id/log',
        name: 'PopupNoticeLog',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/PopupNotices/log.vue'),
        meta: {
            permission: ['SuperAdmin', 'AgencyOwner', "Exec", 'Staff'],
            title: 'Popup Message Log'
        }
    }, {
        path: '/admin/sent-messages/:id',
        name: 'PopupNoticeView',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/PopupNotices/PopupNoticeEdit.vue'),
        meta: {
            permission: ['SuperAdmin', 'AgencyOwner', "Exec", 'Staff'],
            title: 'View Popup Message'
        }
    }, {
        path: '/admin/sent-messages/new',
        name: 'PopupNoticeNew',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/PopupNotices/PopupNoticeEdit.vue'),
        meta: {
            permission: ['SuperAdmin', 'AgencyOwner', "Exec", 'Staff'],
            title: 'New Message'
        }
    }, {
        path: '/admin/bulkuploads/',
        name: 'BulkUploads',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/BulkUploads.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff'],
            title: 'Bulk Uploads'
        }
    }, {
        path: '/admin/staff/',
        name: 'ManageStaff',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/ManageStaff.vue'),
        meta: {
            permission: ['manage:Staff'],
            title: 'Manage Staff'
        }
    }, {
        path: '/admin/audit/agent-audit/:agentcode?',
        name: 'AgentAuditReport',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Audit/AgentAuditReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'manage:Staff'],
            title: 'Agent Audit Report'
        }
    }, {
        path: '/admin/audit/writing-number/:agentcode?',
        name: 'WritingNumberAuditReport',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Audit/WritingNumberAuditReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'manage:Staff'],
            title: 'Writing Number Audit Report'
        }
    }, {
        path: '/admin/audit/user_log/:agentcode?',
        name: 'UserLogReport',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Audit/AgentUserLogReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'manage:Staff'],
            title: 'User Log Report'
        }
    }, {
        path: '/admin/audit/agent_logins',
        name: 'RecentAgentLoginReport',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Audit/RecentAgentLoginReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'manage:Staff'],
            title: 'Recent Agent Login Report'
        }
    }, {
        path: '/admin/audit/logs',
        name: 'AdminLogs',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Audit/Logs.vue'),
        meta: {
            permission: ['SuperAdmin', 'manage:Staff'],
            title: 'Admin Logs'
        }
    }, {
        path: '/admin/carriers',
        name: 'CarrierList',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Carriers.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
            title: 'Carrier List'
        }
    }, {
        path: '/admin/carriers/:carrier_id',
        name: 'CarrierDetail',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/CarrierDetail.vue'),
        meta: {
            permission: ['manage:Carriers'],
            title: 'Carrier Detail'
        }
    }, {
        path: '/admin/products',
        name: 'ProductList',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Products.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
            title: 'Product List'
        }
    }, {
        path: '/admin/products/:product_id',
        name: 'ProductDetail',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/ProductDetail.vue'),
        meta: {
            permission: ['manage:Carriers'],
            title: 'Product Detail'
        }
    }, {
        path: '/admin/AffiliateWidget',
        name: 'AffiliateWidget',
        component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/ConfigureAffiliateWidget.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
            title: 'Affiliate Widget'
        }
    },
	{
		path: '/admin/agent-config-bulk-upload',
		name: 'AgentConfigBulkUpload',
		component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/AgentConfigBulkUpload.vue'),
		meta: {
			permission: ['manage:AgentConfig'],
			title: 'AgentConfig Bulk Upload'
		}
	},
]

export default routes
