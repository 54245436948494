<template>
	<v-menu
		class="q-resource-menu"
		offset-y
		:content-class="menuContentClass"
		:max-width="isMobile ? '100%' : 420"
		:nudge-top="isMobile ? '-12px': 0"
		transition="slide-y-transition"
	>
		<template v-slot:activator="{ on }">
			<v-btn class="q-resource-menu-btn" :small="isMobile" :color="color" text fab v-on="on">
				<v-icon :class="!isMobile && 'q-resource-menu-btn__large'">icon-q-bento-box</v-icon>
			</v-btn>
		</template>

		<v-container class="q-resource-menu__buttons-container" :fluid="isMobile">
			<v-progress-linear class="mb-1" indeterminate v-if="loading || error" height="2px" />

			<v-row align="center" dense>
				<v-col v-bind="gridArgs" v-for="(link, k) in links" :key="k">
					<v-card dark flat v-ripple @click="doLink(link)" class="q-resource-menu__button" :class="checkRestriction(link) && 'q-resource-menu__button-disabled'" :color="link.color">
						<v-icon class="q-resource-menu__icon">{{ link.icon }}</v-icon>
						<p class="q-resource-menu__caption">{{ link.title }}</p>
					</v-card>
				</v-col>
				<v-col v-bind="gridArgs">
					<v-card dark flat v-ripple target="_blank" href="https://quility.com" class="q-resource-menu__button" color="q_green_1" :min-width="!links.length ? 120 : undefined" @click="trackAmplitude('Quility.com')">
						<v-img :src="require('@/assets/images/QuilityLogoSymbolWhiteRGB.svg')" class="q-resource-menu__icon-img" contain />
						<p class="q-resource-menu__caption">Quility.com</p>
					</v-card>
				</v-col>
				<!---
				<v-col cols="6" sm="4" class="pa-1 d-flex justify-space-around ">
					<v-card dark flat v-ripple to="/my-quility" class="pt-4 pb-1 text-center" color="q_green_1" style="width:100%; height:auto;">
						<v-img :src="require('@/assets/images/QuilityLogoSymbolWhiteRGB.svg')" max-height="32px" aspect-ratio="2" contain class="aligncenter" />
						<p class="pt-3 caption font-weight-bold">MyQuility</p>
					</v-card>
				</v-col>
			-->
			</v-row>
		</v-container>
	</v-menu>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI';
import QShopAccountCodes from '@/components/utils/QShopCodes';
import Cookies from 'js-cookie'
import { resourceMap } from './resourceMap';

const LINKS_WITH_RESTRICTION = ['Quility U', 'The LMS']

export default {
    name: 'QResourceMenu',

    props: {
        links: { type: Array, default: () => [] },
		loading: { type: Boolean, default: false },
		error: { type: Boolean, default: false },
		color: { type: String, default: '#005851' },
    },

    components: {
        QShopAccountCodes,
    },

    computed: {
        isMobile () {
			return this.$vuetify.breakpoint.xs
		},
        SwitchboardConfig () {
			return this.getConfig('SwitchboardConfig')
		},
		gridArgs () {
			return this.links.length ? { cols: 6, sm: 4 } : { cols: 6 }
		},
		menuContentClass () {
			return this.isMobile ? 'v-menu--fullscreen' : ''
		},
    },

    methods: {
        doLink(link) {
			if (this.checkRestriction(link)) {
				this.showError(this.getRestrictionMessage(link))
				return
			}

            //removing an offending cookie for pathfinder... this is not a permanent solution. 
            Cookies.remove('a', { path: '/', domain: '.quility.com' });
            QuilityAPI.logActivty('AppMenu', link.title, this.$route.path, {});
            this.trackAmplitude(link.title);

            if (typeof link.function === 'function') {
                link.function();
            } else if (typeof link.function === 'string') {
                this[link.function]()
            } else {
                window.open(link.href, '_blank');
            }
        },
        trackAmplitude(title) {
            const formatted = title.toLowerCase().replaceAll(' ', '');
            const key = Object.keys(resourceMap).find(text => formatted.includes(text));

            if (key) {
                const eventName = resourceMap[key];
                this.$amplitude.track(eventName);
            }
        },
        OptSSO () {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$router.push('/opt-mobile-app')
            } else {
				const OptSSOWindow = window.open('', '_blank')
				OptSSOWindow.document.write('Please wait. Loading ...')
				QuilityAPI.getOptSsoUrl()
					.then((json) => {
						if (json.Success) {
							OptSSOWindow.location.href = json.Message
						} else {
							this.showError(json.Message)
							OptSSOWindow.close()
						}
					}).catch((err) => {
						this.showError(err)
						OptSSOWindow.close()
					})
            }
        },
        gotoShopInstructions() {
            this.showShopPrelude = false;
            this.$router.push('/quility-shop');
        },
		checkRestriction (link) {
			if (this.isImpersonating && LINKS_WITH_RESTRICTION.includes(link.title)) { return true }
			return false
		},
		getRestrictionMessage (link) {
			if (LINKS_WITH_RESTRICTION.includes(link.title)) {
				return `${link.title} doesn't support impersonation.`
			}
			return ''
		},
    },
};
</script>

<style lang="scss">
.v-menu--fullscreen {
  width: 100%;
  left: 0;
}

.q-resource-menu-btn__large.v-icon.v-icon {
	font-size: 30px !important;
}

.q-resource-menu {
    &__buttons-container {
        max-height: calc(100vh - 80px);
        overflow-y: auto;
		padding: 0.5rem 0.75rem !important;
		background-color: #fafafa;
    }

	&__button.v-card {
		position: relative;
		height: auto;
		min-height: 6.25rem;
		padding: 1rem 0.25rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	
		&.q-resource-menu__button-disabled {
			background-color: darkgray !important;
			color: #2e2e2e;
			opacity: 0.6;
		}
	}

	&__icon.v-icon.icon {
		font-size: 2rem !important;
		color: inherit;
	}

	&__icon-img {
		max-height: 2rem;
	}

	&__caption {
		font-size: 0.75rem;
		line-height: 1.2;
		font-weight: bold;
		margin-top: 0.25rem;
		margin-bottom: 0 !important;
		text-align: center;
	}
}
</style>
