import { Model } from '@vuex-orm/core'

export default class Support extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'support'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            Type: this.attr(null),
            Title: this.attr(''),
            Description: this.attr(''),
            LinkText: this.attr(''),
            Link: this.attr(''),
            IsResource: this.attr(false)
        }
    }
}