<template>
    <highcharts v-if="data && data.length > 0" :options="chartOptions"></highcharts>
    <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
import mockdata from '@/store/sample_data/MockDataHighcharts'
import dataTransformer from '@/store/dataTransformer'
import theme from './Theme'

export default {
    name: "QMonthlyPromotionTrackerChart",
    props: ['height', 'data', 'placedData', 'type', "placedGoal", "uwGoal"], // data passed to component from parent

    computed: {
        chartOptions() {
            var g = this;
            var uw_key = "unique_writers"
            var placed_key = "placedAmount"
            var monthlyLabels = dataTransformer.getMonthlyLabels("PeriodMonth", "PeriodYear", this.data, 9)
            var uw = [];
            var placed = dataTransformer.monthlyData(placed_key, "PeriodMonth", "PeriodYear", this.placedData, monthlyLabels);
            //only show placed data, because the promotion goal is only placed apv
            var seriesData = [{
                type: 'column',
                name: 'Placed',
                data: placed,
                colorIndex: 4,
            }];
            //include unique writers if the goal is more than zero.
            if (this.uwGoal > 0) {
                uw = dataTransformer.monthlyData(uw_key, "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
                seriesData = [{
                    type: 'column',
                    name: 'Placed',
                    data: placed,
                    colorIndex: 4,
                }, {
                    type: 'column',
                    name: 'Unique Writers',
                    data: uw,
                    yAxis: 1,
                }]
            }

            return {
                chart: {
                    type: 'column',
                    plotShadow: false,
                    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    zoomType: 'x',
                    height: this.$vuetify.breakpoint.smAndDown ? '75%' : "30%"
                },
                title: {
                    text: '',
                },
                xAxis: {
                    //type: 'datetime',
                    categories: monthlyLabels
                },
                yAxis: [{
                    //currency axes
                    title: {
                        text: 'Placed'
                    },
                    labels: {
                        formatter: function(v) {
                            return g.formatCurrency(this.value);
                        }
                    },
                    softMin: 0,
                    alignTicks: true,
                    allowDecimals: false,
                    plotLines: [{
                        label: {
                            text: "Placed APV Goal",
                            className: 'secondary'
                        },
                        value: g.placedGoal,
                        width: 1,
                        color: '#4CAF50',
                        zIndex: 4,
                        className: 'secondary'
                    }]
                }, {
                    //unique writes axis
                    title: {
                        text: 'Unique Writers'
                    },
                    labels: {
                        formatter: function(v) {
                            return Math.round(this.value);
                        }
                    },
                    opposite: true,
                    softMin: 0,
                    alignTicks: true,
                    allowDecimals: false,
                    plotLines: [{
                        label: {
                            text: "UW Goal",
                            className: 'secondary'
                        },
                        value: g.uwGoal,
                        width: 1,
                        color: '#4CAF50',
                        zIndex: 4,
                        className: 'secondary'
                    }]
                }],
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    //headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function(v) {
                        var value = null
                        if (this.series.name == "Unique Writers") {
                            value = this.y
                        } else {
                            value = g.formatCurrency(this.y)
                        }
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: .2,
                        borderWidth: 0
                    }
                },
                series: seriesData,
            }
        }
    },
    methods: {

    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
