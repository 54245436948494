<template>
    <v-container class="q-data-table-header ma-0" fluid :class="[color]">
        <slot>
            <v-row>
                <v-col class="py-0 d-flex align-center" cols="12" sm="8">
                    <slot name="left"></slot>
                </v-col>
                <v-col class="py-0 d-flex align-center" cols="12" sm="4">
                    <slot name="right"></slot>
                </v-col>
            </v-row>
        </slot>
    </v-container>
</template>

<script>
export default {
    props: {
        color: { type: String, default: 'q_green_1' },
    },
};
</script>

<style lang="scss" scoped>
  .q-data-table-header {
    &__left,
    &__right {
      display: flex;
      align-items: center;
    }
  }
</style>