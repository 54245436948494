<template>
    <Transition name="slideright">
        <div class="q-summit-sidebar-drawer__wrapper" v-if="doShowDrawer" ref="sidebar">
            <v-navigation-drawer class="q-summit-sidebar-drawer" light right width="350px" :mobile-break-point="0">
                <QSummitSidebar />
            </v-navigation-drawer>
        </div>
    </Transition>
</template>
<script>
import { store } from './store/sidebar-store'
import QSummitSidebar from "./QSummitSidebar.vue"

export default {
    components: { QSummitSidebar },

    data() {
        return {
            doCaptureClickOutside: false,
        }
    },

    computed: {
        doShowDrawer: {
            get() { return store.doShowSummitSidebar },
            set(val) { store.doShowSummitSidebar = val }
        }
    },

    mounted() {
        window.addEventListener('click', (e) => {
            if (!this.$refs.sidebar || !this.doCaptureClickOutside) { return }

            if (!this.$refs.sidebar.contains(e.target)) {
                this.doShowDrawer = false
            }
        })
    },

    watch: {
        doShowDrawer(val) {
            if (val) {
                setTimeout(() => this.doCaptureClickOutside = true, 500)
            } else {
                this.doCaptureClickOutside = false
            }
        }
    }
}
</script>
<style lang="scss">
.slideright-leave-active {
    transition: all 0.5s ease;
    width: 350px;
}

.slideright-enter-from,
.slideright-leave-to {
    width: 0px !important;
    overflow: hidden;
}

.slideright-enter-active {
    transition: all 0.5s ease;
    width: 0 !important;
    overflow: hidden;
}

.slideright-leave-from,
.slideright-enter-to {
    width: 350px !important;
}

.slideright-enter-active,
.slideright-leave-active,
.slideright-enter-from,
.slideright-enter-to {
    .q-compass-percentage {
        visibility: hidden;
    }
}

.q-summit-sidebar-drawer {
    z-index: 5;
    overflow-y: auto !important;
    position: relative;
    transition: all 0.5s ease;
    background: transparent !important;
    padding-left: 50px;
    margin-left: -50px;
    box-sizing: content-box;

    &__wrapper {
        position: fixed;
        top: 0;
        z-index: 9;
        &::before {
            content: "";
            position: fixed;
            left: 0;
            width: 100vw;
            top: 0;
            height: 100vh;
            background: rgba($color: #000000, $alpha: .3);
            pointer-events: none;
            z-index: 1;
        }
    }


    .v-navigation-drawer {
        &__content {
            overflow: visible !important;
            position: absolute;
            z-index: 999;
            box-sizing: border-box;
            transition: all 0.5s ease;
        }

        &__border {
            display: none;
        }
    }
}
</style>