import { Model } from '@vuex-orm/core'

export default class Event extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'event'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            Title: this.attr(''),
            Description: this.attr(''),
            StartDate: this.attr(''),
            EndDate: this.attr(''),
            URL: this.attr(''),
            Image: this.attr(''),
            Type: this.attr(''),
            Source: this.attr(''),
            Sponsor: this.attr(''),
            LeadershipLevel: this.attr(''),
        }
    }


}