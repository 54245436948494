<template>
    <v-card class="pa-0 elevation-0 scorecard-card" height="100%">
        <div class="pa-1 pt-2 title-head production">Monthly Sales Data</div>
        <div class="pa-3">
            <div class="stat-notes pb-3">
                Next contract promotion level is at <strong>{{ formatCurrency(placedGoal)}} Placed</strong> <span v-if="uwGoal > 0">and <strong>{{uwGoal}} Unique Writers</strong></span> for <strong>{{consecutiveMonths}}</strong> consecutive months.
            </div>
            <q-monthly-promotion-tracker-chart v-if="!loading && (stats.salesData.length > 0)" :data="stats.salesData" :placed-data="stats.placedData" :placed-goal="placedGoal" :uw-goal="uwGoal"></q-monthly-promotion-tracker-chart>
            <v-progress-linear indeterminate v-else></v-progress-linear>
            <div class="stat-notes">Note: These stats do not factor in the 50% rule. No more than 50% of the required premium for a promotion can come from a single leg of downline business. When being considered for a promotion, the Home Office will factor in the rule above at that time. View <a href="https://hq.quility.com/api/public/document/44375/view/promotion-guidelines" target="_blank">promotion guildelines</a> here.</div>
        </div>
    </v-card>
</template>
<script>
import QMonthlyPromotionTrackerChart from '@/components/charts/QMonthlyPromotionTrackerChart.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QPromotionTracker2",
    props: ["agentCode", "filters", "placedGoal", "uwGoal", "consecutiveMonths"],
    data: function() { // data internal to component (not available outside)
        return {
            stats: {
                "rollingCloseRatio6weeks": {},
                "rollingCloseRatioPrevious6weeks": {},
                "weeklyProduction": {},
                "weeklyProductionPrevious": {},
                "salesData": [],
                "placedData": [],
            },
            loading: false,
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {

    },
    methods: {
        loadData: function() {
            if (typeof this.agentCode == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;

            var params = {}; //you can add "type" to get a certain type of stats - baseshop or total agency or production

            var params = {
                startDate: null,
                endDate: null,
                timePeriod: 'month'
            };
            QuilityAPI.getStatRangePromotion(this.agentCode, params).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        },
    },
    watch: {
        'agentCode': function(newV) {
            this.stats = {
                "rollingCloseRatio6weeks": {},
                "rollingCloseRatioPrevious6weeks": {},
                "monthlyProduction": {},
                "monthlyProductionPrevious": {},
                "salesData": [],
            }
            this.$nextTick(this.loadData);
        },
    },
    components: {
        QMonthlyPromotionTrackerChart
    }
}

</script>
<style scoped>
</style>
