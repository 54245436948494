import Highcharts from 'highcharts';
import drilldown from "highcharts/modules/drilldown";
drilldown(Highcharts);
//import '@/sass/highcharts.css'

var theme = {
    colors: ['#058DC7', '#A5D6A6', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
        '#FF9655', '#FFF263', '#6AF9C4'
    ],
    font: 'Montserrat, sans-serif',
    fontFamily: 'Montserrat, sans-serif',
    chart: {
        backgroundColor: {

        },
        //styledMode: true
    },
    title: {
        style: {
            color: '#000',
            //font: 'Montserrat, sans-serif',
        }
    },
    subtitle: {
        style: {
            color: '#666666',
            //font: 'Montserrat, sans-serif',
        }
    },
    legend: {
        itemStyle: {
            //font: 'Montserrat, sans-serif',
            color: 'black'
        },
        itemHoverStyle: {
            color: 'gray'
        }
    }
};
// Apply the theme
Highcharts.setOptions(theme);
