<template>
    <v-row class="pa-3" justify="space-between" align="start">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-col cols="12" sm="12" md="6" class="text-center ">
            <q-leaderboard-data-table title="Top 10 Personal Producers" :rows="10" :leaderboard-data="leaderboard_data.PersonalProducer" :agentPosition="agentPositions.PersonalProducer" :start-date="startDate" :end-date="endDate"></q-leaderboard-data-table>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="text-center">
            <q-leaderboard-data-table title="Top 10 Agency Directors" :rows="10" :leaderboard-data="leaderboard_data.AgencyDirector" :agentPosition="agentPositions.AgencyDirector" :start-date="startDate" :end-date="endDate"></q-leaderboard-data-table>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="text-center ">
            <q-leaderboard-data-table title="Top 10 Agency Owners" :rows="10" :leaderboard-data="leaderboard_data.AgencyOwner" :agentPosition="agentPositions.AgencyOwner" :start-date="startDate" :end-date="endDate"></q-leaderboard-data-table>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="text-center ">
            <q-leaderboard-data-table title="Top 10 Key Leaders" :rows="10" :leaderboard-data="leaderboard_data.KeyLeader" :agentPosition="agentPositions.KeyLeader" :start-date="startDate" :end-date="endDate"></q-leaderboard-data-table>
        </v-col>
    </v-row>
</template>
<script>
import QLeaderboardDataTable from './QLeaderboardDataTable.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['timeFrame'],
    data: function() {
        return {
            leaderboard_data: {
                PersonalProducer: [],
                AgencyDirector: [],
                AgencyOwner: [],
                KeyLeader: []
            },
            agentPositions: {
                PersonalProducer: {},
                AgencyDirector: {},
                AgencyOwner: {},
                KeyLeader: {}
            },
            startDate: null,
            endDate: null,
            loading: false
        }
    },
    mounted: function() {
        this.loadData()
    },
    methods: {
        loadData: function() {
            var g = this
            this.loading = true;
            QuilityAPI.loadTop5Leadersboards(this.timeFrame).then(function(json) {
                g.$set(g, 'leaderboard_data', json.data)
                g.$set(g, 'agentPositions', json.meta.agent_positions)
                g.$set(g, 'startDate', json.meta.start_date)
                g.$set(g, 'endDate', json.meta.end_date)
                g.loading = false
            }).catch(function() {
                g.loading = false
            })
        }
    },
    components: {
        QLeaderboardDataTable
    },
    watch: {
        'timeFrame': function(newV) {
            this.$nextTick(this.loadData)
        }
    }
}

</script>
