<template>
    <v-dialog v-model="search" width="400" class="search__dialog">
        <v-card light :loading="loading">
			<div class="px-4 py-6">
				<v-text-field label="Search terms" ref="searchfield" v-model="keywords" v-on:keyup="performSearch" filled autofocus hide-details />
				<div class="d-flex mt-6">
					<v-btn large depressed outlined color="#254485" @click="loadIndex">
						View A-Z Index
					</v-btn>
					<v-spacer />
					<v-btn large depressed color="primary" dark right @click="searchResults">
						Search<v-icon small right>fas fa-search</v-icon>
					</v-btn>
				</div>
				
				<div class="mt-6">
                    <p class="search-filters__label">Filter Results:</p>
                    <QSearchFilters v-model="searchCategories" />
				</div>


				<div v-if="search_results.agents.data.length > 0 || search_results.pages.data.length > 0 || search_results.docs.data.length > 0 || elastic_search_results.length > 0">
					<div class="q-subtitle mt-8">
						<h5>Quick Results</h5>
					</div>
                    
					<v-list>
						<v-list-item
                            v-for="result in elastic_search_results"
                            :key="`agent-${result.ID}`"
                            @click="openPage(result)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ getCategoryName(result) }}</v-list-item-title>
                                <span class="q-subtitle blue--text text--darken-3">{{ result.Title }}</span>
                            </v-list-item-content>
                            <v-list-item-action v-if="result.IsExternalResource">
                                <v-icon small>fas fa-external-link-alt</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
				</div>
			</div>
        </v-card>
    </v-dialog>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import SearchResult from '@/store/Models/SearchResult'
import QSearchFilters from './QSearchFilters.vue'

const getEmptyResults = () => ({
	pages: {
		data: [],
		meta: {}
	},
	agents: {
		data: [],
		meta: {}
	},
	docs: {
		data: [],
		meta: {}
	},
})

export default {
	components: { QSearchFilters },
    data () {
        return {
            keywords: null,
            search_results: getEmptyResults(),
            elastic_search_results: [],
			searchCategories: {
				Agent: true,
				AgentDocument: true,
				Page: true,
			},
        }
    },
    computed: {
        search: {
            get () { return this.$store.state.show_search },
            set (v) { this.$store.commit('updateShowSearch', v) },
        },
        loading () {
            return this.$store.state.entities.search_result.loading
        },
		searchCategoriesFilter () {
			return Object.entries(this.searchCategories)
				.filter(([_, value]) => value)
				.map(([key]) => key)
				.join(',')
		}
    },
    watch: {
        search (newV) {
            if (newV) {
                SearchResult.create({ data: {} })
                this.keywords = ''
            }
        },
		searchCategoriesFilter () {
			if (this.keywords) {
				this.loadData()
			}
		},
    },
    methods: {
        openPage (result) {
            if (result.IsExternalResource) {
                window.open(result.URL, '_blank')
            } else {
                const path = result.URL.replace(/^(([^:/?#]+):)?(\/\/([^/?#]*))/g, '')
                this.$router.push(path)
            }
            this.search = false
        },
        loadData: debounce(function() {
			const options = {
				search: this.keywords,
				itemsPerPage: 'quickresults',
				page: 1,
			}
			const filters = { searchModels: this.searchCategoriesFilter }
            QuilityAPI.getElasticSearchResults(filters, options)
				.then((json) => {
                    this.elastic_search_results = json?.data || []
				})
				.catch((err) => {
					if (err.message === 'Aborted') { return }
					console.error(err)
					this.showError('Opps, something went wrong.<br>', err.message)
					this.search_results = getEmptyResults()
				})
        }, 800),
        performSearch (e) {
            if (e.keyCode === 13) {
                this.searchResults()
            } else if (this.keywords) {
                this.loadData()
            }
        },
        searchResults () {
            this.$store.commit('updateShowSearch', false)
			const query = { keyword: this.keywords, searchModels: this.searchCategoriesFilter }
            this.$router
				.push({ name: 'SearchResults', query })
				.catch(err => { this.showError('Whoops! ' + err) })
        },
		loadIndex () {
            this.$router.push('/site-index')
            this.search = false
        },
		getCategoryName (result) {
            switch (result.Model) {
                case 'AgentDocument': { return 'Document' }
                case 'Agent': { return result.LeadershipLevel }
                case 'Page': { return 'Page' }
                default: { return 'Predefined' }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.search-filters__label {
	margin-bottom: 0.25rem;
	font-size: 0.875rem;
	font-weight: 600;
}
.v-list-item__title{
	font-size: 0.75rem;
	font-weight: 100;
}
</style>
