const routes = [{
        name: "SummitAdmin",
        path: '/summit/admin',
        component: () => import( /* webpackChunkName: "summit" */ '../views/Summit/ManageSummit.vue'),
        meta: {
            permission: ['manage:Summit'],
            title: 'Summit Admin'
        }
    },
    {
        name: "SummitNavSampleAdmin",
        path: '/summit/nav',
        component: () => import( /* webpackChunkName: "summit" */ '../views/Summit/SummitNavSample.vue'),
        meta: {
            title: 'Summit Nav'
        }
    },
    {
        path: '/summit/:step_slug',
        name: 'SummitStep',
        component: () => import( /* webpackChunkName: "cms_view" */ '../views/Summit/SummitStep.vue'),
        meta: {
            title: 'Summit Step'
        }
    },
    {
        path: '/summit',
        name: 'SummitHome',
        component: () => import( /* webpackChunkName: "summit" */ '../views/Summit/SummitHome.vue'),
        meta: {
            title: 'Summit Home'
        }
    }
]

export default routes