const routes = [{
    path: '/admin/reports/onboarding_report',
    name: 'Agents',
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin/Reports/OnboardingReport.vue'),
    meta: {
        permission: ['SuperAdmin', "Exec", "Staff"]
    }
}]

export default routes
