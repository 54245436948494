
import { Notice } from '@/store/API/PopupAcknowledgements';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    notice: { type: Object as PropType<Notice>, required: true },
    color: { type: String, default: 'q_messages_3' }
  },

  computed: {
    doShowAcknowledgeButton() {
      return this.currentPopup?.add_acknowledge_btn == 1 || !this.currentPopup?.link
    },
    doShowLinkButton() {
      return this.currentPopup?.link != null
    },
    currentPopup() {
      return this.notice.popup
    },
    computedLink() {
      if (!this.currentPopup?.link) { return null }
      if (this.currentPopup?.link?.startsWith('/')) {
        return this.currentPopup.link
      }
      if (/^https?:\/\//.test(this.currentPopup?.link)) {
        return this.currentPopup.link
      }
      return `https://${this.currentPopup.link}`
    }
  },

  methods: {
    onAcknowledgeLinkClicked() {
      this.$emit('link-clicked')
      this.$emit('acknowledge')
    },
    onAcknowledgeClicked() {
      this.$emit('acknowledge-clicked')
      this.$emit('acknowledge')
    }
  }
})
