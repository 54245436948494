<template>
    <v-card v-if="!isDemoMode" id="impersonate-banner">
        <v-banner single-line sticky color="rgb(255, 235, 59, 0.8)" class="d-flex text-center body-2">
            You are currently impersonating <b>{{ $auth.auth_user.name}}</b>.
            <v-btn depressed small color="q_blue_1" dark class="ml-5" @click="revertImpersonation">
                Switch back <v-icon class="pl-3" color="q_blue_5">fas fa-undo-alt</v-icon>
            </v-btn>
        </v-banner>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QImpersonateBanner",
    data: () => ({}),
    methods: {
        revertImpersonation() {
            QuilityAPI.revertImpersonation()
                .then(function(json) {
                    if (json.success == true) {
                        //redirect the admin back to the detail page for that agent. 
                        document.location.href = process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                    }
                })
        },
    },
}

</script>
<style scoped>
#impersonate-banner {
    margin: 8px 55px;
    position: fixed;
    z-index: 50;
    background-color: transparent;
}

</style>
