<template>
    <v-card class="pa-0 elevation-0 scorecard-card" height="100%">
        <div :class="'pa-1 pt-2 title-head ' + cardStyle">
            {{title}}
            <v-btn x-small @click="loadData" v-if="allowRefresh" dark icon>
                <v-icon right small>fas fa-redo-alt</v-icon>
            </v-btn>
        </div>
        <div :class="'pa-3 body-stat ' + cardStyle">
            <div class="stat-graph">
                <v-sparkline v-if="!loading && most_current" color="white" :value="graph_data" :smooth="0" :padding="5" :line-width="4" :fill="false" type="trend" auto-draw></v-sparkline>
            </div>
            <div class="stat-detail">
                <div class="score-card-label" v-if="!loading && most_current">
                    <span v-if="the_data.timePeriod == 'month'">
                        {{ monthFromDate(most_current.PeriodYear + "/" + most_current.PeriodMonth + "/01") }}
                        <div class="stat-percentage">
                            {{ formatMonthDay(most_current.startDate) }} - {{ formatMonthDay(most_current.endDate) }}
                        </div>
                    </span>
                    <span v-else-if="most_current.monthName">{{ most_current.monthName }}</span>
                    <span v-else>Ending {{ formatMonthDay(most_current.endDate) }}</span>
                </div>
                <div class=" score-card-label" v-else>
                    <span>&nbsp;</span>
                </div>
                <div class="stat-number pt-4" v-if="!loading && most_current">
                    <span :class="most_current.Amount > 999999 ? 'large' : ''">
                        <span v-if="format == 'Currency'">{{ formatCurrency(most_current.Amount) }}</span>
                        <span v-else-if="format == 'Percentage'">{{ formatPercentage(most_current.Amount) }}</span>
                        <span v-else>{{ most_current.Amount }}</span>
                    </span>
                </div>
                <div class="stat-number" v-else>
                    <v-progress-circular :indeterminate="loading || !most_current"></v-progress-circular>
                </div>
                <div class="stat-percentage" v-if="format != 'Percentage' && !loading && !hideGoal">
                    {{formatPercentage(progress_bar_percent)}}
                </div>
                <div class="stat-percentage" v-else>
                    &nbsp;
                </div>
            </div>
        </div>
        <div v-if="!loading && !hideGoal" :class="'footer-progress '  + cardStyle">
            <span class="progress-bar" :style="'width : ' + progress_bar_width + '%'"></span>
        </div>
        <div v-else-if="!hideGoal" :class="'footer-progress '  + cardStyle"></div>
        <div class="footer-expand">
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header class="expansion-label">
                        <div v-if="!hideGoal">
                            Goal :&nbsp;
                            <span v-if="format == 'Currency'">{{ formatCurrency(the_data.Goal) }}</span>
                            <span v-else-if="format == 'Percentage'">{{ formatPercentage(the_data.Goal) }}</span>
                            <span v-else>{{ the_data.Goal }}</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <table class="historical-data" width="100%" v-if="historical_data.length > 0 && !hideHistoricalData">
                            <tr v-for="d in historical_data">
                                <td>
                                    <span v-if="the_data.timePeriod == 'month'">{{ monthFromDate(d.PeriodYear + "/" + d.PeriodMonth + "/01") }}</span>
                                    <span v-else-if="the_data.timePeriod == 'minutes'">{{ d.monthName }}</span>
                                    <span v-else>{{ formatMonthDay(d.startDate) }} to {{ formatMonthDay(d.endDate) }}</span>
                                </td>
                                <td class="text-right">
                                    <span v-if="format == 'Currency'">{{ formatCurrency(d.Amount) }}</span>
                                    <span v-else-if="format == 'Percentage'">{{ formatPercentage(d.Amount) }}</span>
                                    <span v-else>{{ d.Amount }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center" colspan="2" v-if="next_time">
                                    <v-btn @click="loadMoreData" icon v-if="!loading_more && !loading">
                                        <v-icon>fas fa-plus-circle</v-icon>
                                    </v-btn>
                                    <v-progress-circular indeterminate v-else-if="loading_more"></v-progress-circular>
                                </td>
                            </tr>
                        </table>
                        <div class="stat-notes">
                            <slot></slot>
                        </div>
                        <v-divider class="py-3"></v-divider>
                        <div class="stat-goal text-right pb-4" v-if="!hideGoal && user.Agent.AgentCode == agentCode">
                            <v-text-field label="Set Goal" v-model="the_data.Goal"></v-text-field>
                            <v-btn @click="resetGoal">Default</v-btn>
                            <v-btn color="primary" @click="saveGoal">Save</v-btn>
                        </div>
                        <v-btn block color="primary" @click="gotoDrillDown" v-if="filters.statType != 'PersonalProduction' && drillDownRoute">
                            <v-icon left v-if="!hideButtonIcon">fas fa-chart-pie </v-icon>
                            {{ drillDownLabel }}
                        </v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QScoreCard",
    props: {
        'title': {
            type: String,
            default: 'Title'
        },
        'format': {
            type: String,
            default: ''
        },
        'label': {
            type: String,
            default: 'Label'
        },
        'apiEndpoint': {
            type: String,
            default: 'submitted'
        },
        'drillDownLabel': {
            type: String,
            default: 'Agent Breakdown'
        },
        'drillDownRoute': {
            type: String,
            default: 'Drilldown_Submitted'
        },
        'cardStyle': {
            type: String,
            default: ''
        },
        'agentCode': {
            type: String,
            default: null
        },
        'filters': {
            type: Object,
            default: function() {
                return {}
            }
        },
        'defaultGoal': {
            type: Number,
            default: null
        },
        'hideGoal': {
            type: Boolean,
            default: false
        },
        'allData': {
            type: Boolean,
            default: false
        },
        'hideButtonIcon': {
            type: Boolean,
            default: false
        },
        'hideHistoricalData': {
            type: Boolean,
            default: false
        },
        'allowRefresh': {
            type: Boolean,
            default: false
        },
        'dataRequiresFilters': {
            // If set to true data load won't be called on mounted, will wait for filter values to load.
            // Could probably default to true but haven't checked with all the cards.
            type: Boolean,
            default: false,
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            the_data: {
                data: [],
                Goal: null,
            },
            loading: false,
            loading_more: false
        }
    },
    mounted: function() {
        if (! this.dataRequiresFilters) {
            this.loadData();
        }
    },
    computed: {
        progress_bar_width: function() {
            return this.progress_bar_percent * 100;
        },
        progress_bar_percent: function() {
            if (typeof this.most_current == 'undefined' || this.most_current == null) {
                return 0;
            }
            if (typeof this.most_current.Amount == 'undefined' || this.most_current.Amount == 0) {
                return 0;
            }
            if (this.most_current.Amount / this.the_data.Goal > 1) {
                return 1
            }
            return this.most_current.Amount / this.the_data.Goal;
        },
        most_current: function() {
            if (typeof this.the_data == 'undefined' || this.the_data.data.length == 0) {
                return null
            }
            this.$emit('most_current', this.the_data.data[this.the_data.data.length - 1]);
            return this.the_data.data[this.the_data.data.length - 1];
        },
        graph_data: function() {
            var r = [];
            this.the_data.data.forEach(function(i) {
                r.push(i.Amount)
            })
            return r;
        },
        historical_data: function() {
            //only returns the previous 3 months of data.
            if (typeof this.the_data == 'undefined' || this.the_data.data.length < 2) {
                return []
            }
            var r = [];

            let cutOff = this.allData ? 1 : 2

            for (var i = this.the_data.data.length - cutOff; i >= 0; i--) {
                var d = this.the_data.data[i];
                if (typeof d != 'undefined') {
                    r.push(d);
                }
            }
            return r
        },
        next_time: function() {
			const g = this
			let last_date = null
            if (typeof g.the_data.data[0] != 'undefined') {
                last_date = g.formatDate(g.the_data.data[0].endDate)
            } else {
                last_date = g.formatDate(g.filters.endDate)
            }
            return g.getNextBusinessTimePeriod(last_date, g.filters.timePeriod);
        }
    },
    methods: {
        saveGoal: function() {
            //save the goal to the AgentConfig
            QuilityAPI.saveScoreCardGoal(this.agentCode, this.apiEndpoint + "_" + this.filters.statType, this.the_data.Goal).then(function(json) {
                if (!json.success) {
                    g.showError("There was a problem saving your new goal.");
                }
            }).catch(function(error) {
                g.showError("There was a problem saving your new goal.");
            })
        },
        setCurrentGoal: function() {
            if (typeof this.defaultGoal != 'undefined' && this.defaultGoal != null) {
                this.the_data.Goal = this.defaultGoal
                return
            }
            if (typeof this.the_data.data[this.the_data.data.length - 2].Amount != 'undefined') {
                this.the_data.Goal = Math.round(this.the_data.data[this.the_data.data.length - 2].Amount * 1.05);
                return
            }
            this.the_data.Goal = null;
        },
        resetGoal: function() {
            this.setCurrentGoal();
            this.saveGoal();
        },
        loadData: function() {
            var g = this
            this.loading = true
            //get the data for the agent for this scorecard.
            var q_name = "getScoreCard_" + this.apiEndpoint
            QuilityAPI.cancelQRequest(q_name);
            var params = {
                ...this.filters
            }
            QuilityAPI.getRequest("/api/private/scorecards/" + this.apiEndpoint + "/" + this.agentCode, params, q_name).then(function(json) {
                    g.the_data = json
                    g.loading = false;
                    if (json.Goal == null) {
                        g.$nextTick(g.setCurrentGoal());
                    }
                },
                function(error) {
                    g.loading = false;
                    //g.showError("There was a problem loading the '" + g.title + "' scorecard. Give it a few minutes and try refreshing.")
                    return error
                })
            .catch(function(err) {
                console.log(err)
            });
        },
        loadMoreData: function() {
            const g = this
            g.loading_more = true
            //get the data for the agent for this scorecard.
            let q_name = "getScoreCard_" + g.apiEndpoint
            QuilityAPI.cancelQRequest(q_name)
            let params = {
                ...g.filters
            }
			let last_date = null
            if (typeof g.the_data.data[0] != 'undefined') {
                last_date = g.formatDate(g.the_data.data[0].endDate);
            } else {
                last_date = g.formatDate(params.endDate);
            }
            let next = this.getNextBusinessTimePeriod(last_date, params.timePeriod);
			if (next) {
				params.startDate = next.startDateFormat
				params.endDate = next.endDateFormat

				QuilityAPI.getRequest("/api/private/scorecards/" + g.apiEndpoint + "/" + g.agentCode, params, q_name).then(function(json) {
					let d = json.data.concat(g.the_data.data)
					g.the_data.data = d;
					g.loading_more = false
				},
				function(error) {
					g.loading_more = false
					return error
				})
			} else {
				g.loading_more = false
				g.loading = false
			}
        },
        getNextBusinessTimePeriod(endDate, stat_period) {
            let available = this.getAvailableBusinessDates(stat_period, false, true)
            for (var i = 0; i < available.length; i++) {
                if (available[i].endDateFormat == endDate) {
                    return available[i + 1] != 'undefined' ? available[i + 1] : null
                }
            }
            return null;
        },
        gotoDrillDown: function() {
			const g = this
            let params = {
                agent_code: g.agentCode,
            }
			let mostRecentPeriod = this.the_data.data[this.the_data.data.length - 1];
            g.$router.push({
                name: g.drillDownRoute,
                params: params,
                query: {
                    endDate: g.filters.endDate ? g.filters.endDate : g.formatDate(mostRecentPeriod.endDate),
                    startDate: g.filters.startDate ? g.filters.startDate : g.formatDate(mostRecentPeriod.startDate),
                    timePeriod: g.filters.timePeriod,
                    statType: g.filters.statType,
                    phase: g.filters.phase, // for Summit stats
                }
            });
        }
    },
    watch: {
        'agentCode': function(newV, oldV) {
            var g = this;
            var q_name = "getScoreCard_" + this.apiEndpoint
            QuilityAPI.cancelQRequest(q_name);
            this.the_data = {
                data: [],
                Goal: null,
            }
            g.$nextTick(g.loadData)
        },
        filters: {
            deep: true,
            handler: function() {
                var q_name = "getScoreCard_" + this.apiEndpoint
                QuilityAPI.cancelQRequest(q_name);
                this.the_data = {
                    data: [],
                    Goal: null,
                }
                var g = this
                g.$nextTick(g.loadData);
            }
        }
    },
}
</script>
<style scoped>
    .score-card-label .stat-percentage {
        text-align: left;
    }
</style>
