<template>
    <div class="q-marketing-resources-filters">
        <v-btn text small color="q_leads_4" @click="toggleExpand()">
            <v-icon class="filter-funnel-q" small left>icon-filter-funnel-q</v-icon> Filters
        </v-btn>

        <div v-show="expanded" class="q-marketing-resources-filters__search">
            <v-text-field
                v-model="filters.search"
				:disabled="isDemoMode"
                prepend-icon="fas fa-search"
                hide-details
                class="q-marketing-resources-filters__search-input"
                placeholder="Search by the file name, description or key word"
                @input="updateFilters()"
            />
        </div>

        <v-row v-show="expanded">
            <v-col cols="12" md="4">
                <v-select label="File Type" v-model="filters.type" :items="filtersVariants.types" clearable @input="updateFilters()" />
            </v-col>
            <v-col cols="12" md="4">
                <v-select label="Subject" v-model="filters.subject" :items="filtersVariants.subjects" clearable @input="updateFilters()" />
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-center align-center">
                <v-btn-toggle class="q-marketing-resources-filters__audience-switch">
                    <v-btn color="#0083EB" @click="toggleAudience('Agent')">Agent</v-btn>
                    <v-btn color="#46C3B2" dark @click="toggleAudience('Customer')">Customer</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
  	name: 'MarketingResourceFilters',

	props: {
		value: { type: Object, default: () => ({}) },
		filtersVariants: { type: Object, default: () => ({}) },
	},

	data () {
		return {
			expanded: false,
			filters: {
				search: '',
				type: '',
				subject: '',
				audience: '',
            },
		}
	},

    created() {
        this.syncFilters()
    },

	watch: {
		value () {
            this.syncFilters()
        },
	},

	methods: {
        syncFilters () {
			this.filters = { ...this.value }
        },
        resetFilters() {
            this.filters = {
				search: '',
				type: '',
				subject: '',
				audience: '',
            }
            this.updateFilters()
        },
        toggleExpand() {
            this.expanded = !this.expanded
            if (!this.expanded) {
                this.resetFilters()
            }
        },
		toggleAudience (to) {
			if (this.filters.audience === to) {
				this.filters.audience = ''
			} else {
				this.filters.audience = to
			}
            this.updateFilters()
		},
        updateFilters() {
            this.$emit('input', this.filters)
        },
	},
}
</script>

<style lang="scss" scoped>
.q-marketing-resources-filters {
    width: 100%;
    padding: 1rem 0;

    &__search {
        max-width: 600px;
        margin: 0 auto 1rem;
    }

    &__search-input {
        width: 100%;
        padding-top: 0 !important;
        margin-top: 0 !important;
    }

    &__audience-switch {
        .v-btn__content {
            color: white !important;
        }

        &:has(.v-btn--active) {
            .v-btn:not(.v-btn--active) {
                opacity: 0.5 !important;
            }
        }

        .v-btn--active {
            font-weight: 800 !important;
        }
    }
}
</style>
