import moment from 'moment'
var numeral = require('numeral');
export default {
    monthlyData(column, month_column, year_column, orig_data, labels) {
        //yuckky... we have to create copy of the data or any segmentation will apply to the data GLOBALLY. 
        var data = orig_data.slice();
        var m = {};
        //we pass in only the labels we want...
        //sets all months in the range to 0
        if (typeof labels == 'undefined') {
            labels = this.getMonthlyLabels(month_column, year_column, orig_data)
        }
        for (var i = 0; i < labels.length; i++) {
            m[labels[i]] = 0;
        }
        //var r = [];
        for (var i = 0; i < data.length; i++) {
            var dateStr = data[i][year_column] + "-" + data[i][month_column] + "-01"
            var date = moment(dateStr, 'YYYY-MM-DD', 'America/New_York');
            //we populate data only for the labels we want. Ones we have set to 0.
            if (m[date.format('MMM') + " " + date.format('YYYY')] == 0) {
                m[date.format('MMM') + " " + date.format('YYYY')] = Number(data[i][column]);
            }
        }
        var r = Object.entries(m);
        return r;
    },

    middleMonthlyData(column, date_column1, date_column2, data) {
        var r = [];
        for (var i = 0; i < data.length; i++) {
            var date1 = moment(data[i][date_column1], 'YYYY-MM-DD', 'America/New_York');
            var date2 = moment(data[i][date_column2], 'YYYY-MM-DD', 'America/New_York');
            var d = Math.round(date1.valueOf() + ((date2.valueOf() - date1.valueOf()) / 2))
            r.push([d, Number(data[i][column])]); // uses timestamp
            //r.push([date.format('MMM'), Number(data[i][column])]); // uses month name
        }
        return r;
    },

    getMonthlyLabels(month_column, year_column, orig_data, segments) {
        //yuckky... we have to create copy of the data or any segmentation will apply to the data GLOBALLY. 
        var data = orig_data.slice();
        var r = [];
        //find min max dates
        if (data.length == 0) {
            return r;
        }
        var date = moment();

        for (var i = segments - 1; i >= 0; i--) {
            r.push(moment().subtract(i, "months").format('MMM') + " " + moment().subtract(i, "months").format('YYYY'))
        }
        return r;

    },

    pieChart(orig_data) {
        if (typeof orig_data == 'undefined' || orig_data == null) {
            return [];
        }
        var data = orig_data
        var r = [];
        Object.keys(data).forEach(function(key) {
            r.push({
                name: key,
                y: data[key]
            })
        });
        return r;
    },

    dailyPlaced() {
        var t = Date.now() + (1000 * 60 * 60 * 24 * 31);
        var data = [];
        var d = new Date()
        d.setTime(t);
        var cd = 20000
        for (var i = 1; i <= 13; i++) {
            var n = Math.random();
            if (n > .5) {
                cd = cd + Math.round(Math.random() * 100);
            } else {
                cd = cd - Math.round(Math.random() * 100);
            }
            data.push([d.getTime(), cd]);
            t = t - (1000 * 60 * 60 * 24 * 31)
            d.setMonth(d.getMonth() - 1)
        }
        return data
    },
    dailyUniqueWriters() {
        var t = Date.now() + (1000 * 60 * 60 * 24 * 31);
        var data = [];
        var d = new Date()
        d.setTime(t);
        var cd = 1
        for (var i = 1; i <= 13; i++) {
            var n = Math.random();
            if (n > .5) {
                cd = cd + Math.round(Math.random() * 10);
            } else {
                cd = cd - Math.round(Math.random() * 10);
            }
            if (cd < 0) { cd = 1 }
            data.push([d.getTime(), cd]);
            t = t - (1000 * 60 * 60 * 24 * 31)
            d.setMonth(d.getMonth() - 1)
        }
        return data
    },
    monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ],
    monthlyPlaced() {
        var t = Date.now();
        var labels = [];
        var data = [];
        var data2 = [];
        var data3 = [];
        var cd = 20000
        //var cd2 = 19000
        //var cd3 = 2
        var d = new Date()
        d.setTime(t);
        for (var i = 1; i < 6; i++) {
            var n = Math.random();
            if (n > .5) {
                cd = cd + Math.round(Math.random() * 1000);
            } else {
                cd = cd - Math.round(Math.random() * 1000);
            }
            var n = Math.random();
            if (n > .5) {
                cd2 = cd2 + Math.round(Math.random() * 1000);
            } else {
                cd2 = cd2 - Math.round(Math.random() * 1000);
            }
            var n = Math.random();
            if (n > .5) {
                cd3 = cd3 + Math.round(Math.random() * 10);
            } else {
                cd3 = cd3 - Math.round(Math.random() * 10);
            }
            if (cd3 <= 0) {
                cd3 = 1;
            }
            data.push(cd);
            data2.push(cd2);
            data3.push(cd3);
            //labels.push(d.toISOString());
            labels.push(this.monthNames[d.getMonth()]);
            t = t - (1000 * 60 * 60 * 24 * 31)
            d.setTime(t)
        }
        labels.reverse();
        return { 'labels': labels, 'data': data, 'data2': data2, 'data3': data3 }
    },

    //expects two arrays that have an array where the label is the first slot and the value is the seconds. 
    //Used to take two data sets (for monthly timeline graphs) and find the conversion rate between the two. 
    conversionRate(data1, data2){
        var r = [];
        for(var i = 0; i < data1.length; i++){
            var label = data2[i][0];
            var cr = data2[i][1] / data1[i][1];
            r.push([label, cr]);
        }
        return r;
    }
}
