export default [{
    path: '/my-quility',
    name: 'MyQuility',
    component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/MyQuility.vue'),
    meta: {
        title: 'My Quility'
    },
    children: [
        {
            path: '',
            name: 'MyQuilityIndex',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/Index.vue'),
            meta: {
                title: 'My Quility'
            },
        },
        {
            path: 'enrollment',
            name: 'MyQuilityEnrollment',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/Enrollment.vue'),
            meta: {
                title: 'My Quility Enrollment'
            },
        },
        {
            path: 'my-points',
            name: 'MyQuilityMyPoints',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/MyPoints.vue'),
            meta: {
                title: 'My Quility My Points'
            },
        },
        {
            path: 'resources/my-quility',
            name: 'ResourcesMyQuility',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/resources/MyQuility.vue'),
            meta: {
                title: 'My Quility Resources'
            },
        },
        {
            path: 'resources/quility-rx',
            name: 'ResourcesQuilityRx',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/resources/QuilityRx.vue'),
            meta: {
                title: 'My Quility Resources RX'
            },
        },
        {
            path: 'leaderboards/my-quility',
            name: 'LeaderboardsMyQuility',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/leaderboards/MyQuility.vue'),
            meta: {
                title: 'My Quility Resources'
            },
        },
        {
            path: 'leaderboards/quility-rx',
            name: 'LeaderboardsQuilityRx',
            component: () => import( /* webpackChunkName: "quilityu" */ '../views/MyQuility/pages/leaderboards/QuilityRx.vue'),
            meta: {
                title: 'My Quility Resources RX'
            },
        },
    ]
}]
