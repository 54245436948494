<template>
    <v-snackbar
        :value="value"
        right
        top
        absolute
        :timeout="0"
        color="q_new_blue"
        @input="$emit('input', $event)"
    >
        <div class="q-notification-menu-policies-alert">
            <v-icon>icon-q-currency-dollar-circle</v-icon>
            <span class="ml-2">You have {{ unreadNotificationCount }} pending polic{{ unreadNotificationCount === 1 ? 'y' : 'ies' }}</span>
            <v-btn
                color="white"
                text
                class="mx-0"
                :to="{ name: 'Pending Policy'}"
                @click="$emit('input', false)"
            >
                View Now
            </v-btn>
            <v-btn
                color="white"
                text
                class="mx-0"
                @click="$emit('input', false)"
            >
                <v-icon @click="$emit('input', false)">icon-x-circle-q</v-icon>
            </v-btn>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    name: "QNotificationMenuPoliciesAlert",
    props: {
        value: { type: Boolean, required: true },
        unreadNotificationCount: { type: [Number, String], required: true },
    },
}
</script>

<style lang="scss" scoped>
.q-notification-menu-policies-alert {
    display: flex;
    align-items: center;
    gap: 1rem;
}
</style>
