import { computed, ref } from 'vue'

const isDate = (date: unknown) => date instanceof Date && !isNaN(date.valueOf())

export const useLocalStorageItem = <Value>(key: string, defaultValue: Value) => {
    const localStorageValue = ref(localStorage.getItem(key))

    const valueComputed = computed<Value>({
        get() {
            const parsed = JSON.parse(localStorageValue.value || '')

            if (isDate(defaultValue)) {
                return new Date(parsed as number) as unknown as Value
            }

            return parsed as Value
        },
        set(value: Value) {
            const newValue = JSON.stringify(value)

            localStorage.setItem(key, newValue)
            localStorageValue.value = newValue
        },
    })

    if(!localStorageValue.value) {
        valueComputed.value = defaultValue
    }

    return valueComputed
}