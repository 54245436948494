import { render, staticRenderFns } from "./GratitudesJournalCelebrations.vue?vue&type=template&id=2c8ff488&scoped=true&"
var script = {}
import style0 from "./GratitudesJournalCelebrations.vue?vue&type=style&index=0&id=2c8ff488&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8ff488",
  null
  
)

export default component.exports