<template>
    <v-expansion-panel class="q-summit-sidebar-exapansion-panel">
        <QSummitExpansionPanelHeader :category="category" :color="color">
            <QCompassProgress :currentStep="currentStepIndex" :totalSteps="steps.length" :color="color" />
        </QSummitExpansionPanelHeader>

        <v-expansion-panel-content>
            <QSummitGuide :steps="steps" :currentStep="currentStep" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import QSummitExpansionPanelHeader from './ExpansionPanelHeader.vue'
import QSummitGuide from '../QSummitGuide.vue'
import QCompassProgress from '../../QCompassProgress.vue'
import { colors } from '../../phases'

export default {
    components: { QSummitExpansionPanelHeader, QSummitGuide, QCompassProgress },

    props: {
        category: {
            type: String,
            required: true,
        },
        steps: {
            type: Array,
            required: true,
        },
        currentStep: {
            type: Object,
            required: true,
        },
    },

    computed: {
        color() { return colors[this.category.toLowerCase().replaceAll(/ /g, '')] },
        currentStepIndex() { 
            return this.steps.reduce((acc, step) => {
                if (step.Completed) { return acc + 1}
                return acc
            }, 0)
        },
    }
}
</script>

<style lang="scss">
    @keyframes compass {
        0% {
            transform: translateY(50%);
            opacity: 0;
        }
        100% {
            transform: translateX(-50%) translateY(50%);
            opacity: 1;
        }
    }

  .q-summit-sidebar-exapansion-panel {
    position: relative;
    &::before {
        display: none;
    }

    &.v-expansion-panel--active {
        .q-compass-percentage {
            transform: translateX(-50%) translateY(0%);
            opacity: 1;
        }
    }

    .q-compass-percentage {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(50%);
        max-height: 95px;
        max-width: 95px;
        transition: all 0.5s ease-in-out;
        opacity: 0;
        animation: compass 0.5s ease-in-out;
        z-index: 99999;
    }
  }
</style>
