<template>
	<v-list-item
		class="navigation-list-submenu-item"
		exact-active-class="navigation-list-item__item-active"
		v-bind="routerLinkProps"
	>
		<v-list-item-content>
			<v-list-item-title>
				<v-icon v-if="icon" left :small="small">{{ icon }}</v-icon>
				<template v-if="label">{{ label }}</template>
				<slot v-else />
			</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	props: {
		label: { type: String },
		icon: { type: String },
		route: { type: [String, Object] },
		path: { type: String },
		href: { type: String },
		small: { type: Boolean },
	},
    computed: {
		routerLinkProps() {
			if (this.href) {
				return {
					href: this.href,
					target: '_blank',
				}
			}

			if (this.route) {
				if (typeof this.route === 'string') {
					// Sometimes route can be just a path, not name
					if (this.route.startsWith('/')) {
						return {
							to: this.route,
						}
					}

					return {
						to: { name: this.route },
					}
				}

				return {
					to: this.route,
				}
			}

			if (this.path) {
				if (this.path.startsWith('http')) {
					return {
						href: this.path,
						target: '_blank',
					}
				}

				return {
					to: this.path,
				}
			}

			console.error('No route/path/href provided to SubMenuItem')
			return {}
		},
    },
}
</script>
