<!--Two column block-->
<template>
    <div></div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
export default {
    props: [],
    data: () => ({
        percentage_watched: 0
    }),
    created() {
        this.startTrackingVideos();
    },
    computed: {

    },
    methods: {
        //adds event watchers
        startTrackingVideos() {
            var g = this;
            window._wq = [];
            _wq.push({
                id: "_all",
                onReady: function(video) {
                    video.bind('play', function() {
                        console.log('Playing')
                        g.logPlayingStarted(video._hashedId)
                    });
                    video.bind('end', function() {
                        console.log('Finished')
                        g.logCompletedVideo(video._hashedId)
                    });

                    video.bind('percentwatchedchanged', function(p) {
                        g.logPercentWatched(video._hashedId, p)
                    });
                }
            });
        },
        //logs started and progress. 
        logPlayingStarted(id) {
            QuilityAPI.logWistiaEvent("started", id)
        },
        logPercentWatched(id, percent) {
            var p = Math.round(percent * 10)
            if (p > this.percentage_watched) {
                this.percentage_watched = p;
                QuilityAPI.logWistiaEvent("watched " + Math.round(percent * 100) + "%", id)
            }
        },
        //logs when finished. 
        logCompletedVideo(id) {
            console.log('Video finished!')
            QuilityAPI.logWistiaEvent("finished", id)
        },
    },
    watch: {

    }
}
</script>