<template>
    <QResourceMenu :links="links" />
</template>
<script>
import QResourceMenu from './QResourceMenu';

export default {
    name: 'QResourceMenuCorporate',
    components: {
        QResourceMenu,
    },

    computed: {
        links() {
            if (this.hasRole('UnlicensedAgent')) {
                return [{
                        href: 'https://quility.lightspeedvt.com/',
                        title: 'Quility U',
                        color: 'q_blue_1',
                        icon: 'icon-quility-u',
                    },
                    {
                        href: 'https://www.concursolutions.com/nui/signin',
                        title: 'Travel Portal',
                        color: 'q_support_1',
                        icon: 'icon-q-travel',
                    },
                ];
            }
            return [{
                    title: 'Opt!',
                    color: 'q_leads_1',
                    icon: 'icon-q-leads',
                    function: 'OptSSO',
                },
                {
                    href: 'https://mail.quilitymail.com/mail/',
                    title: 'Email',
                    color: 'q_resources_1',
                    icon: 'icon-q-email',
                },
                {
                    href: 'https://quote.ipipeline.com/LTSearch.aspx?GAID=1364',
                    title: 'iPipeline',
                    color: 'q_new_b_1',
                    icon: 'icon-ipipeline',
                },
                {
                    href: 'https://formspipe.ipipeline.com/?GAID=1364',
                    title: 'Formpipe',
                    color: 'q_new_b_2',
                    icon: 'icon-q-formpipe',
                },
                {
                    href: 'https://quote.ipipeline.com/LTSearch.aspx?GAID=1364',
                    title: 'TermQuoter',
                    color: 'q_new_b_3',
                    icon: 'icon-q-formpipe',
                },
                {
                    href: "https://quility.auth0.com/samlp/sDVWPBhK4y9OFW0KG69WPY704csV49lg",
                    title: "Quility U",
                    color: "q_blue_1",
                    icon: "icon-quility-u",
                },
                {
                    href: 'https://www.concursolutions.com/nui/signin',
                    title: 'Travel Portal',
                    color: 'q_support_1',
                    icon: 'icon-q-travel',
                },
            ];
        },
    },
};
</script>