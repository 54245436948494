<template>
    <v-snackbar
        :value="value"
        right
        top
        absolute
        :timeout="0"
        color="warning"
        @input="$emit('input', $event)"
    >
        <div style="width: 100%">
            <div>
                <v-icon>fas fa-bell</v-icon>
                <span class="ml-2">You have {{ unreadMessageCount }} unread notification{{ unreadMessageCount === 1 ? '' : 's' }}</span>
            </div>
            <div class="d-flex align-center justify-end" style="width: 100%">
                <v-btn
                    color="white"
                    class="ma-0"
                    text
                    @click="$emit('input', false)"
                >
                    Dismiss
                </v-btn>

                <v-btn
                    color="white"
                    class="ma-0"
                    text
                    :to="{ name: 'Notices' }"
                    @click="$emit('input', false)"
                >
                    Read
                </v-btn>
            </div>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    name: "QNotificationMenuMessagesAlert",
    props: {
        value: { type: Boolean, required: true },
        unreadMessageCount: { type: [Number, String], required: true },
    },
}
</script>
