import { Model } from '@vuex-orm/core'

export default class Image extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'image'

    static primaryKey = 'id'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/images',
            per_page: null,
            total: null,
            filter_keys: [],
            availableFilters: null,
            has_more: true
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            title: this.attr(''),
            file: this.attr(''),
            url: this.attr(''),
            description: this.attr(''),
            category_id: this.attr(''),
            id: this.uid(),
            division: this.attr('All')
        }
    }
}
