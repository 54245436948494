/**
 * Instead of making additional column in db to determine if popup should be a FML form or not
 * we use token in popup content.
 */

const FML_TOKEN_REGEXP = /\[FML_FORM\]\((.*?)\)/
const FML_TOKEN_REGEXP_GLOBAL = new RegExp(FML_TOKEN_REGEXP, 'g')

/** 
 * Add FML token with slug inside of token, so we can decide 
 * if popup should be a FML form or not
 */
export const addFMLToken = (str: string) => {
  return `[FML_FORM](${str})`
}

/** Removes FML token from string. If there is not token just returns provided string */
export const removeFMLToken = (str: string) => {
  return str.match(FML_TOKEN_REGEXP)?.[1] || str
}

export const hasFMLToken = (str: string) => {
  return FML_TOKEN_REGEXP.test(str)
}

export const parseContent = (str: string) => {
  const fmlToken = str?.match?.(FML_TOKEN_REGEXP)?.[0]

  if (fmlToken) {
    return {
      fmlToken,
      content: str.replace(fmlToken, ''),
    }
  }

  return {
    fmlToken: '',
    content: str,
  }
}

export const combineContent = (content: string, fmlToken: string) => {
  return `${content}${fmlToken}`
}

export const parseContentWithMultipleFML = (str: string) => {
	const fmlTokens = str?.match?.(FML_TOKEN_REGEXP_GLOBAL)

	if (fmlTokens) {
		let content = str
		fmlTokens.forEach(token => { content = content.replace(token, '') })
		return {
			fmlTokens,
			fmlSlugs: fmlTokens.map(removeFMLToken),
			content,
		}
	}

	return {
		fmlTokens: [],
		fmlSlugs: [],
		content: str,
	}
}
