import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    import_data() {
        return new Promise(function(resolve, reject) {
            QuilityAPI.getDataInit().then(function() {
                QuilityAPI.getTickets().then(function() {

                });
                resolve(true)
            }, function(err) {
                console.log(err)
                reject(err);
            })
        });
    },
    import_summit() {
        return new Promise(function (resolve, reject) {
            QuilityAPI.getSummitDataInit().then(function () {
                resolve(true)
            }, function (err) {
                console.log(err)
                reject(err);
            })
        });
    }
}
