var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.form && _setup.form.FormQuestionSets)?_c('div',{staticClass:"form-with-steps-renderer"},[_c('div',{staticClass:"mb-2 form-with-steps-renderer__progress"},[_c('v-progress-linear',{attrs:{"color":_vm.color,"value":(_setup.currentStepIndex + 1) / _setup.form.FormQuestionSets.length * 100,"top":""}}),_c('span',{staticClass:"ml-2 form-with-steps-renderer__progress-number"},[_vm._v(" "+_vm._s(_setup.currentStepIndex + 1)+" of "+_vm._s(_setup.form.FormQuestionSets.length)+" ")])],1),_c(_setup.FmlQuestionSet,{attrs:{"slug":_vm.slug,"questionSet":_setup.currentStep,"hideSubmitButton":_vm.$props.hideSubmitButton,"last-set":_setup.currentStepIndex === _setup.form.FormQuestionSets.length - 1,"doNotSubmit":_vm.doNotSubmit,"color":_vm.color,"redirect-on-save":_vm.$props.redirectOnSave},on:{"saved":_setup.onSave},scopedSlots:_vm._u([{key:"controls",fn:function({
        saveResponse,
        disabled,
        lastSet,
      }){return [_vm._t("controls",function(){return [_c('v-card-actions',{staticClass:"pa-0"},[(_setup.currentStepIndex !== 0)?_c(_setup.QButton,{attrs:{"type":"secondary","color":_vm.color},on:{"click":_setup.back}},[_vm._v(" Back ")]):_vm._e(),_c('v-spacer'),_c(_setup.QButton,{attrs:{"disabled":disabled,"color":_vm.color},on:{"click":saveResponse}},[_vm._v(" "+_vm._s(lastSet ? 'Submit' : 'Next')+" ")])],1)]},null,{
          onClick: saveResponse,
          back: _setup.back,
          disabled,
          lastSet,
          firstSet: _setup.currentStepIndex === 0,
        })]}}],null,true),model:{value:(_setup.form.FormResponse),callback:function ($$v) {_vm.$set(_setup.form, "FormResponse", $$v)},expression:"form.FormResponse"}})],1):(_setup.errorMessage)?_c('div',[_c('v-alert',{staticClass:"mb-8",attrs:{"type":"error"}},[_vm._v(_vm._s(_setup.errorMessage))]),_vm._t("controls",null,null,{ disabled: true, onClick: () => {}, back: () => {}, lastSet: false, firstSet: true })],2):_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }