<template>
    <div v-if="step.StepIcon" class="q-summit-sidebar-guide-icon" :style="{ height }" :class="{ 'q-summit-sidebar-guide-icon--disabled': disabled }">
        <template v-if="step.StepIcon">
            <img :src="step.StepIcon" />
        </template>
        <template v-else>
            <v-icon v-if="step.StepType === 'Milestone'" color="white" class="vuetify-fix-icon">fas fa-flag</v-icon>
            <v-icon v-if="step.StepType === 'Checkpoint'" color="white" class="vuetify-fix-icon">fas fa-map-marker-alt</v-icon>
            <v-icon v-if="step.StepType === 'Skill Block'" color="white" class="vuetify-fix-icon">fa-light fa-bolt-lightning</v-icon>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        url: { type: String, required: false },
        step: { type: Object, required: true },
        height: { type: [String, Number], required: false },
        disabled: { type: Boolean, required: false }
    },

    data() {
        return {
            svgContent: ''
        }
    },

    computed: {
        isSvg() {
            return this.url.endsWith(".svg")
        }
    },
}
</script>
<style lang="scss">
.q-summit-sidebar-guide-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled {
        filter: grayscale(1);
        opacity: 0.5;
    }

    img {
        height: 100%;
    }
}
</style>