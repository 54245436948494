<template>
    <div>
        <q-score-card key="QScoreCardUniqueWriters" title="Unique Writers" api-endpoint="uniquewriters" drill-down-label="Agent Breakdown" drill-down-route="Drilldown_UniqueWriters" :card-style="cardStyle" :agent-code="agentCode" :filters="filters" :default-goal="defaultGoal">
            Note: This is the number of individual agents who wrote at least one policy.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardUniqueWriters",
    props: ["agentCode", "filters", "defaultGoal", "cardStyle"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
