import * as amplitude from '@amplitude/analytics-browser'

export default {
    install(app) {
        app.prototype.$amplitude = {
            initialize(user) {
                const API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
                const options = {
                    defaultTracking: {
                        sessions: true,
                        pageViews: true,
                        formInteractions: true,
                        fileDownloads: true
                    }
                }

                if (user) {
                    const identifyEvent = new amplitude.Identify();
                    identifyEvent.set('AgentCode', user.AgentCode);
                    identifyEvent.set('LeadershipLevel', user.LeadershipLevel);
                    identifyEvent.set('Role', user.Role);
                    identifyEvent.set('ContractLevel', user.Agent?.ContractLevel);
                    identifyEvent.set('IsSFGDirect', user.IsSFGDirect);
                    identifyEvent.set('ProducerLevel', user.ProducerLevel);
                    identifyEvent.set('Status', user.Status);
                    amplitude.identify(identifyEvent);
                }

                amplitude.init(API_KEY, user?.email ?? undefined, options)
                    .promise
                    .then(function (result) {
                        console.info('Amplitude has been initialized')
                    });
            },
            track(event, eventProperties = null) {
                amplitude.track(event, eventProperties)
            }
        }
    }
}