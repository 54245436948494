<template>
    <div 
        class="q-summit-guide-circle"
        :class="{ 
            'q-summit-guide-circle--right': right,
            'q-summit-guide-circle--icon': $slots.default
        }"

        :style="{ background: $slots.default && computedColor, color: !$slots.default && computedColor }"
    >
        <div v-if="!$slots.default" class="q-summit-guide-circle__figure" :style="{ 'border-color': color }" />
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        right: { type: Boolean, default: false, },
        color: { type: String, default: 'transparent' }
    },

    computed: {
        computedColor() { return this.getColor(this.color) }
    }
}
</script>

<style lang="scss">
.q-summit-guide-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__figure {
        border: 2px solid;
        border-color: #015851;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background: white;
    }
    
    width: 12px;
    height: 12px;

    &--right {
        margin-left: auto;
        right: 0;
    }

    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    .q-summit-sidebar-guide-icon {
        height: 100%;
    }

    &:first-child, &:last-child {
        background: white;
    }
}
</style>