<template>
    <v-card max-height="70vh" style="background-color:transparent;" class=" elevation-0">
        <v-card-title>
            <h3 class="text-left with-short-rule">TCPA Agreement for the Quility Portal</h3>
        </v-card-title>
        <v-card-text>
            <div class="scroll elevation-0 pa-4" style="height: calc(60vh - 175px); text-align:left; border:#ccc 1px solid; ">
                <page slug="agency-agreement"></page>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <div>

                <v-btn color="primary" large href="https://hq.quility.com/api/public/document/223374/view/1694681377/62116c53a4d27aa42ce504def377f948" target="_blank">View TCPA Agreement</v-btn>
                <p class="agreement-tooltip">
                    Please type <strong>{{userName}}</strong> in the space below to confirm that you have reviewed and agreed to the terms of the TCPA Agreement.
                </p>
                <v-text-field v-model="fullName" :label="`Type ${userName} for acceptance`" />

                <v-btn
                    :disabled="!fullNameTyped"
                    tile
                    depressed
                    color="primary"
                    large
                    @click="acceptAgencyAgreement"
                    >Accept</v-btn
                >
                <v-btn tile depressed @click="rejectAgencyAgreement">Reject</v-btn>
            </div>
            <v-spacer />
        </v-card-actions>
        <v-progress-linear indeterminate v-if="config_loading"></v-progress-linear>
    </v-card>
</template>

<script>
import Page from '@/CMS/views/index.vue'
import ReleaseMeta from '@/ReleaseMeta.json'
import PageStore from '@/store/Models/Page'

function stripHtml(html)
{
   const tmp = document.createElement('div');
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || '';
}

export default {
    props: [],
    data() {
        return {
            config_loading: false,
            fullName: '',
        }
    },
    computed: {
        userName() {
            return this.user.Agent?.AgentName
        },
        fullNameTyped() {
            return this.fullName === this.userName
        },
    },
    methods: {
        acceptAgencyAgreement() {
            this.config_loading = true
            const page = PageStore.query().where('slug', 'agency-agreement').first();
            const body = page.blocks.filter(({ type }) => type === 'full-width-text')
                .map(({ text }) => text)
                .join(' ')
            const bodyText = stripHtml(body)
            const value = {
                AgentSignature: this.fullName,
                AgreementBody: bodyText,
            }
            this.updateConfig("AcceptedAgencyAgreement " + ReleaseMeta.Version, value, "json").then((json) => {
                this.config_loading = false
            })
        },
        rejectAgencyAgreement() {
            this.config_loading = true
            this.updateConfig("AcceptedAgencyAgreement " + ReleaseMeta.Version, null, "json").then((json) => {
                this.config_loading = false
                this.$auth.logout({
                    returnTo: window.location.origin
                });
            })
        }
    },
    components: {
        Page,
    }
}

</script>

<style scoped>
.agreement-tooltip {
    width: 700px;
    margin: 0.75rem 0;
}
</style>