//import { Page } from './PageTypes'
import { Model } from '@vuex-orm/core'

export class GlobalSessionData extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'global_session_data'
    //static baseEntity = 'page'

    static state() {
        return {
            stat_period: 'months',
            stat_date_selection: null,

            leaderboard_period: 'month',
            leaderboard_date_selection: null,
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.uid(''),
        }
    }
}

export default GlobalSessionData
