const routes = [
    //{
    //    path: '/leads/available_leads',
    //   name: 'AvailableLeads',
    //    component: () => import( /* webpackChunkName: "leads" */ '../views/Leads/AvailableLeads.vue')
    //},
    {
        path: '/leads',
        name: 'Leads',
        component: () => import( /* webpackChunkName: "leads" */ '../views/Leads/Leads.vue')
    },
    {
        path: '/leads/lead-orders-by-state',
        name: 'Lead Orders by State',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/LeadOrdersByState.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/the-moloney-report',
        name: 'The Moloney Report',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/LeadOrdersMoloneyDemandReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/the-moloney-fillrate-report',
        name: 'The Moloney Report',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/LeadOrdersMoloneyFillrateReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/lead-orders-fillrate-report',
        name: 'DX Lead Orders by State',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/LeadOrderFillrateReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/baseshop-lead-order-report',
        name: 'DX Lead Orders by State',
        component: () => import( /* webpackChunkName: "leads" */ '../views/Leads/AgencyLeadOrders.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec', 'AgencyOwner'],
        }
    },
    {
        path: '/leads/allocate-leads',
        name: 'Allocate DX Leads',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/AllocateLeads.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/recruiting-lead-orders',
        name: 'Recruiting Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/Recruiting/AgentRecruitingLeadOrders.vue'),
        meta: {
            permission: ['agent:RecruitingLeadOrders']
        }
    },
    {
        path: '/leads/quility-digital-lead-orders',
        name: 'Quility Digital Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/AgentDigitalLeadOrders.vue'),
        meta: {
            permission: ['agent:Quility Digital LeadLeadOrders']
        }
    },
    {
        path: '/leads/call-in-lead-orders',
        name: 'Call In Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/AgentCallInLeadOrders.vue'),
        meta: {
            permission: ['agent:Call InLeadOrders']
        }
    },
    {
        path: '/leads/dfl-lead-orders',
        name: 'Debt Free Life Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/AgentDflLeadOrders.vue'),
        meta: {
            // permission: ['agent:Debt Free LifeLeadOrders']
        }
    },
    {
        path: '/leads/approve-digital-lead-orders',
        name: 'Approve Digital Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/ApproveDigitalLeadOrders.vue'),
        meta: {
            permission: ['AgencyOwner', 'SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/approve-lead-credit-requests',
        name: 'Approve Lead Credit Requests',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/ApproveLeadCreditRequests.vue'),
        meta: {
            permission: ['AgencyOwner', 'SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/lead-sales-summary',
        name: 'Lead Sales Summary',
        component: () => import( /* webpackChunkName: "leads" */ '../views/Agent/AgentAccounts/LeadOrderReceiptSummary.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/lead-sales-receipts',
        name: 'Lead Sales Receipts',
        component: () => import( /* webpackChunkName: "leads" */ '../views/Agent/AgentAccounts/LeadOrderReceipts.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },{
        path: '/leads/lead-credit-report',
        name: 'Lead Credit Report',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/QuilityDigitalLeads/LeadCreditReport.vue'),
        meta: {
            permission: ['SuperAdmin', 'Staff', 'Exec'],
        }
    },
    {
        path: '/leads/existing-lead-order-auction',
        name: 'Existing Lead Order Auction',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/Auction/ExistingLeadOrderAuction.vue')
    },
    {
        path: '/agents/transactions',
        name: 'Transaction Report',
        component: () => import( /* webpackChunkName: "leads" */ '../views/Agent/AgentAccounts/Transactions.vue')
    },
    {
        path: '/leads/allocation-report',
        name: 'Allocation Report',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/LeadAllocationReport.vue')
    },
    {
        path: '/leads/lighthouse-lead-orders',
        name: 'Lighthouse Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/Lighthouse/LighthouseLeadOrdersPage.vue'),
        meta: {
            permission: ['agent:LighthouseLeadOrders'],
        }
    },
	{
        path: '/leads/economy-lead-orders',
        name: 'Economy Lead Orders',
        component: () => import( /* webpackChunkName: "leads" */ '../views/LeadOrders/Economy/EconomyLeadOrdersPage.vue'),
        meta: {
            permission: ['agent:EconomyLeadOrders'],
        }
    }
]

export default routes