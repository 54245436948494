const routes = [{
        path: '/user/profile',
        name: 'User Profile',
        component: () => import( /* webpackChunkName: "user" */ '../views/User/Profile.vue')
    },
    {
        path: '/user/settings',
        name: 'User Settings',
        component: () => import( /* webpackChunkName: "user" */ '../views/User/Settings.vue')
    },
    {
        path: '/user/notifications',
        name: 'User Notifications',
        component: () => import( /* webpackChunkName: "user" */ '../views/User/Notifications.vue')
    },
    {
        path: '/user/my_exports',
        name: 'My Exports',
        component: () => import( /* webpackChunkName: "user" */ '../views/User/MyExports.vue')
    },
    {
        path: '/user/pcs_program',
        name: 'PCS Program',
        component: () => import( /* webpackChunkName: "user" */ '../views/User/PCSProgram.vue')
    },
]

export default routes
