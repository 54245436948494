<template>
    <v-toolbar class="px-8" flat style="width:100%; border-bottom: 1px solid #eee;">
        <div class="d-flex" style="width:100%;" ref="breadcrumbs">
            <div class="d-flex flex-grow-1 align-center" ref="items">
                <span class="text-no-wrap opacity-60" @click="goTo(firstElement)">
                    {{ firstElement.title }}
                </span>
                <v-menu v-if="hiddenBreadcrumbs.length" offset-y>
                    <template v-slot:activator="{ on }">
                        <div class="d-flex align-center">
                            <span class="mx-4">/</span>
                            <span class="pointer text-no-wrap" v-on="on">
                                ...
                            </span>
                        </div>
                    </template>
                    <v-list style="min-width:230px;">
                        <div
                            v-for="(item, index) in hiddenBreadcrumbs"
                            :key="`hidden_${index}`"
                            text
                            class="ma-5 d-flex pointer"
                            @click="goTo(item)"
                        >{{ item.title }}</div>
                    </v-list>
                </v-menu>
                <div
                    v-for="(item, index) in visibleBreadcrumbs"
                    :key="index"
                    class="d-flex align-center"
                >
                    <span class="mx-4">/</span>
                    <span
                        :class="{
                            'pointer': index !== visibleBreadcrumbs.length - 1,
                            'opacity-60': index === visibleBreadcrumbs.length - 1,
                        }"
                        class="text-no-wrap"
                        @click="goTo(item)"
                    >
                        {{ item.title }}
                    </span>
                </div>
            </div>
        </div>
    </v-toolbar>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            hiddenBreadcrumbsAmount: null,
        }
    },
    computed: {
        breadcrumbsSorted() {
            return [...this.breadcrumbs].sort(() => -1)
        },
        firstElement() {
            return this.breadcrumbsSorted[0]
        },
        visibleBreadcrumbs() {
            const startPosition = (this.hiddenBreadcrumbsAmount || 0) + 1
            return [...this.breadcrumbsSorted].slice(startPosition)
        },
        hiddenBreadcrumbs() {
            return this.hiddenBreadcrumbsAmount
                ? [...this.breadcrumbsSorted].slice(1, this.hiddenBreadcrumbsAmount + 1)
                : []
        },
    },
    mounted() {
        this.reorganizeBreadcrumbs()
    },
    methods: {
        goTo(target) {
            if (target.link) {
                console.log(target.link)
                this.$router.push(target.link)
            }
            if (target.href) {
                window.open(target.href, '_blank');
            }
        },
        reorganizeBreadcrumbs() {
            const ELLIPSIS_WIDTH = 50

            requestAnimationFrame(() => {
                if (this.$refs.items.offsetWidth > this.$refs.breadcrumbs.offsetWidth) {
                    const width = this.$refs.breadcrumbs.offsetWidth
                    const items = this.$refs.items.children

                    let index = items.length - 1
                    const firstElement = items[0]
                    let totalWidth = firstElement.offsetWidth
                    while (index > 0) {
                        const button = items[index]
                        totalWidth += button.offsetWidth

                        if (totalWidth > width || (totalWidth + ELLIPSIS_WIDTH > width && index > 1)) {
                            break
                        }

                        index--
                    }

                    this.hiddenBreadcrumbsAmount = index
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .opacity-60 {
        opacity: 0.6;
    }
</style>
