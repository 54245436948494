import { watchEffect } from "vue";
import { getInstance } from "./index";
import User from '../store/Models/User'
import noop from 'lodash/noop'

export const authGuard = (router) => {
    // router is our router instance so we can access it later down the chain
    return (to, from, next) => {
        const authService = getInstance();

        let stopWatcher = noop

        // Clear watcher and redirect to next route
        const stopWatcherAndNext = (redirect = undefined) => {
            stopWatcher()
            next(redirect)
        }

        // Our auth flow is based on following reactive variables:
        // 1. authService.loading - we do nothing until auth is loaded
        // 2. authService.isAuthenticated - we redirect to login page if auth failed
        // 3. User.query().first() - we do nothing until user is loaded or we check if user has permission
        //                           to access the route if route requires permission
        // WatchEffect will be re-executed when any of the variables change
        stopWatcher = watchEffect(async () => {
            // Wait until auth is loaded
            if (authService.loading) { return }

            // If auth failed redirect to login page
            if (!authService.isAuthenticated) {
                return authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
            }

            // If route has permission we need to load user and check if user has permission
            if (to.meta.permission) {
                let user = User.query().first();

                // Wait until user is loaded
                if (!user) { return }

                const checkPermission = (permission) => {
                    if (authService.auth_user['http://quility.com/roles'].indexOf(permission) > -1) {
                        return true;
                    }
                    if (user.permissions.indexOf(permission) > -1) {
                        return true;
                    }
                    return false;
                }

                if (typeof to.meta.permission == 'object') { // permission is an array of strings
                    for (var i = 0; i < to.meta.permission.length; i++) {
                        if (checkPermission(to.meta.permission[i])) {
                            return stopWatcherAndNext();
                        }
                    }
                } else { // permission is a string
                    if (checkPermission(to.meta.permission)) {
                        return stopWatcherAndNext();
                    }
                }

                return stopWatcherAndNext('/403');
            }

            // Check if there is a custom view to route them to 
            const roleRoute = router.resolve({ name: to.name + "_" + authService.auth_user['http://quility.com/roles'][0], params: to.params });
            if (roleRoute.resolved.matched.length > 0) {
                roleRoute.resolved.matched[0].path = to.path;
                roleRoute.resolved.matched[0].params = to.params;
                return stopWatcherAndNext(roleRoute.resolved.matched[0]);
            }
            return stopWatcherAndNext();
        })
    };
};
